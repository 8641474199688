import * as Yup from "yup";
//@ts-ignore
import { ABARoutingNumberIsValid } from "bank-routing-number-validator";

interface reasonCodeType {
  personalInfo: boolean;
  bankingInfo: boolean;
}

export const createValidationSchema = (
  sumarySectionLastName: string,
  reasonCode: reasonCodeType,
  showPersonalSection: boolean,
  showReasonCodeSection: boolean,
  showBankAccountSection: boolean,
  showAdminSection: boolean,
  noBankAvailable: boolean,
  showOrgSection: boolean,
  SSNOverride: boolean,
  isCorp: boolean,
  hasBanking: boolean,
  isOrg: boolean,
  otherOrgSelected: boolean,
  isAdminView: boolean,
  isW9: boolean,
  llcSelected: boolean
) => {
  return Yup.object().shape({
    SSN1:
      SSNOverride ||
      showPersonalSection ||
      (showReasonCodeSection && reasonCode.personalInfo)
        ? Yup.string()
            .required("All SSN Fields must be filled.")
            .test("Length", "Invalid SSN", (value) => {
              return !!value && !isNaN(Number(value)) && value.length === 3;
            })
        : Yup.string(),
    SSN1Confirm:
      SSNOverride ||
      showPersonalSection ||
      (showReasonCodeSection && reasonCode.personalInfo)
        ? Yup.string()
            .required("All SSN Fields must be filled.")
            .oneOf([Yup.ref("SSN1")], "SSN Fields do not match")
        : Yup.string(),
    SSN2:
      SSNOverride ||
      showPersonalSection ||
      (showReasonCodeSection && reasonCode.personalInfo)
        ? Yup.string()
            .required("All SSN Fields must be filled.")
            .test("Length", "Invalid SSN", (value) => {
              return !!value && !isNaN(Number(value)) && value.length === 2;
            })
        : Yup.string(),
    SSN2Confirm:
      SSNOverride ||
      showPersonalSection ||
      (showReasonCodeSection && reasonCode.personalInfo)
        ? Yup.string()
            .required("All SSN Fields must be filled.")
            .oneOf([Yup.ref("SSN2")], "SSN Fields do not match")
        : Yup.string(),
    SSN3:
      SSNOverride ||
      showPersonalSection ||
      (showReasonCodeSection && reasonCode.personalInfo)
        ? Yup.string()
            .required("All SSN Fields must be filled.")
            .test("Length", "Invalid SSN", (value) => {
              return !!value && !isNaN(Number(value)) && value.length === 4;
            })
        : Yup.string(),
    SSN3Confirm:
      SSNOverride ||
      showPersonalSection ||
      (showReasonCodeSection && reasonCode.personalInfo)
        ? Yup.string()
            .required("All SSN Fields must be filled.")
            .oneOf([Yup.ref("SSN3")], "SSN Fields do not match")
            .test("catchall", "Invalid SSN", function () {
              let SSN = this.parent.SSN1 + this.parent.SSN2 + this.parent.SSN3
              return !(/^(.)\1+$/.test(SSN) || (SSN && SSN.includes(" ")));
            })
        : Yup.string(),
    accountType:
      (showBankAccountSection ||
        (showReasonCodeSection && reasonCode.bankingInfo)) &&
      (!noBankAvailable || (isOrg && hasBanking))
        ? Yup.string().required("Please select an Account Type")
        : Yup.string(),
    routingNumber:
      (isOrg && !hasBanking) ||
      ((showBankAccountSection ||
        (showReasonCodeSection && reasonCode.bankingInfo)) &&
        !noBankAvailable &&
        !hasBanking)
        ? Yup.string()
            .required("Routing Number field cannot be empty")
            .test(
              "testRoutingValidity",
              "Invalid routing number",
              (value) =>
                value && value.length === 9 && ABARoutingNumberIsValid(value)
            )
        : Yup.string(),
    routingNumberConfirm:
      (isOrg && !hasBanking) ||
      ((showBankAccountSection ||
        (showReasonCodeSection && reasonCode.bankingInfo)) &&
        !noBankAvailable &&
        !hasBanking)
        ? Yup.string()
            .required("Routing Number validation field cannot be empty")
            .oneOf(
              [Yup.ref("routingNumber")],
              "Routing Number Fields must match"
            )
        : Yup.string(),
    accountNumber:
      (isOrg && !hasBanking) ||
      ((showBankAccountSection ||
        (showReasonCodeSection && reasonCode.bankingInfo)) &&
        !noBankAvailable &&
        !hasBanking)
        ? Yup.string()
            .matches(/^[a-zA-Z0-9-]+$/, "Account Number Invalid")
            .max(17, "Account Number Invalid")
            .required("Account Number field cannot be empty")
        : Yup.string(),
    accountNumberConfirm:
      (showBankAccountSection ||
        (showReasonCodeSection && reasonCode.bankingInfo)) &&
      !noBankAvailable &&
      !hasBanking
        ? Yup.string()
            .required("Account Number validation field cannot be empty")
            .oneOf(
              [Yup.ref("accountNumber")],
              "Account Number Fields must match"
            )
        : Yup.string(),
    signature: Yup.string().required("Digital Signature Required"),
    signatureDate: Yup.string()
      .nullable()
      .test("DateSelected", "Signature Date Required", (value) => {
        return !!value;
      }),
    adminPaymentDate: Yup.string().test(
      "adminPaymentDate",
      "Payment Date Must Be set",
      (value) => {
        if (!showAdminSection) {
          return true;
        }
        return !!value;
      }
    ),
    orgSelection:
      isOrg && !isW9
        ? Yup.string().required("Federal Tax Classification is required")
        : Yup.string(),
    orgOther:
      otherOrgSelected && isOrg && !isW9
        ? Yup.string().required("Field cannot be empty")
        : Yup.string(),
    EIN:
      isOrg && !SSNOverride && !isW9
        ? Yup.string()
            .required("EIN field cannot be empty")
            .matches(/^\d{9}|\d{2}-\d{7}$/, "Invalid EIN")
            .test("Length", "Invalid EIN", (value) => {
              if (value === "00-0000000" || value === "000000000") {
                return false;
              }
              if (!!value && value.indexOf("-") > -1) {
                return !!value && value.length === 10;
              } else {
                return !!value && value.length === 9;
              }
            })
        : Yup.string(),
    EINVerify:
      isOrg && !SSNOverride && !isW9
        ? Yup.string()
            .required("Field cannot be empty")
            .oneOf([Yup.ref("EIN")], "EIN Fields do not match")
        : Yup.string(),
    EINName:
      isOrg && !SSNOverride && !isW9
        ? Yup.string().required("EIN Name field cannot be empty")
        : Yup.string(),
    SSNName:
      SSNOverride && !isW9
        ? Yup.string().required("SSN Name field cannot be empty")
        : Yup.string(),
    payeeName: Yup.string(),
    repName:
      isCorp && !isW9
        ? Yup.string().required("Representative Name Required")
        : Yup.string(),
    repEmail:
      isCorp && !isW9
        ? Yup.string().required("Representative Email Required")
        : Yup.string(),
    llcType:
      isOrg && llcSelected
        ? Yup.string()
            .required("LLC Classification required")
            .oneOf(
              ["c", "s", "p"],
              "Invalid Classification. Only C, S, or P is allowed"
            )
        : Yup.string(),
  });
};
