import styled from "@emotion/styled";
import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import {
  InputFull,
  FormContainer,
  FormHeader,
  Divider,
  FormInputLabel,
  ErrorLabel,
} from "src/components/forms";
import { FormikInitValues } from "../HeroSubmissionForm";

export const Spacer = styled.div`
  margin-right: 2em;
`;

const PersonalInformationFormContainer = styled.div`
  flex-direction: column;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-grow: 0.1;
`;
export const SSNContainer = styled.div`
  display: flex;
`;
export const SSNSection = styled.div`
  flex: 0.3;
`;

export interface SSNCollectionType {
  SSN1: string;
  SSN1Confirm: string;
  SSN2: string;
  SSN2Confirm: string;
  SSN3: string;
  SSN3Confirm: string;
}

interface PersonalInformationSectionTypes {
  isDisabled?: boolean;
}

export function PersonalInformationSection(
  props: FormikProps<FormikInitValues> & PersonalInformationSectionTypes
) {
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const {
    errors,
    setFieldValue,
    setFieldTouched,
    touched,
    isDisabled,
    values,
  } = props;

  const changeSSNField = (
    field: keyof SSNCollectionType,
    value: string,
    MAX_LENGTH: string
  ) => {
    const focusOrder = [
      "SSN1",
      "SSN2",
      "SSN3",
      "SSN1Confirm",
      "SSN2Confirm",
      "SSN3Confirm",
    ];
    setFieldValue(field, value, true);
    if (value.length.toString() === MAX_LENGTH) {
      const nextField = focusOrder.indexOf(field);
      if (focusOrder[nextField + 1]) {
        document.getElementById(focusOrder[nextField + 1])?.focus();
      } else {
        document.getElementById(focusOrder[nextField])?.blur();
      }
    }
  };

  const blurSSNField = (field: keyof SSNCollectionType, value: string) => {
    setFieldTouched(field, true, false);
  };

  useEffect(() => {
    if (values.tin) {
      setFieldValue("SSN1", values.tin.slice(0, 3), false);
      setFieldValue("SSN2", values.tin.slice(3, 5), false);
      setFieldValue("SSN3", values.tin.slice(5, 9), false);
      setFieldValue("SSN1Confirm", values.tin.slice(0, 3), false);
      setFieldValue("SSN2Confirm", values.tin.slice(3, 5), false);
      setFieldValue("SSN3Confirm", values.tin.slice(5, 9), false);
      setReadOnly(true);
    }
  }, [values.tin, setFieldValue, setReadOnly]);

  const isError: boolean = !!(
    (errors.SSN1 && touched.SSN1) ||
    (errors.SSN2 && touched.SSN2) ||
    (errors.SSN3 && touched.SSN3) ||
    (errors.SSN1Confirm && touched.SSN1Confirm) ||
    (errors.SSN2Confirm && touched.SSN2Confirm) ||
    (errors.SSN3Confirm && touched.SSN3Confirm)
  );
  return (
    <PersonalInformationFormContainer>
      <Divider />
      <FormHeader>Personal Information</FormHeader>
      <FormContainer>
        <SSNSection>
          <LabelContainer>
            <FormInputLabel data-cy="SSNLabel" error={isError}>
              Social Security Number
            </FormInputLabel>
          </LabelContainer>
          <SSNContainer>
            <InputFull
              disabled={isDisabled || readOnly}
              value={values.SSN1}
              data-cy="SSN1"
              onChange={(e) => {
                changeSSNField("SSN1", e.target.value, "3");
              }}
              id="SSN1"
              error={!!(errors.SSN1 && touched.SSN1)}
              onBlur={(e) => {
                blurSSNField("SSN1", e.target.value);
              }}
              small
              maxLength={3}
            />
            <InputFull
              disabled={isDisabled || readOnly}
              value={values.SSN2}
              data-cy="SSN2"
              onChange={(e) => {
                changeSSNField("SSN2", e.target.value, "2");
              }}
              id="SSN2"
              error={!!(errors.SSN2 && touched.SSN2)}
              onBlur={(e) => {
                blurSSNField("SSN2", e.target.value);
              }}
              small
              maxLength={2}
            />
            <InputFull
              disabled={isDisabled || readOnly}
              value={values.SSN3}
              data-cy="SSN3"
              onChange={(e) => {
                changeSSNField("SSN3", e.target.value, "4");
              }}
              id="SSN3"
              error={!!(errors.SSN3 && touched.SSN3)}
              onBlur={(e) => {
                blurSSNField("SSN3", e.target.value);
              }}
              small
              maxLength={4}
            />
          </SSNContainer>
          {isError && (
            <ErrorLabel data-cy="SSNErrorLabel">
              {errors.SSN1 ||
                errors.SSN2 ||
                errors.SSN3 ||
                errors.SSN1Confirm ||
                errors.SSN2Confirm ||
                errors.SSN3Confirm}
            </ErrorLabel>
          )}
        </SSNSection>
        <Spacer />
        <SSNSection>
          <LabelContainer>
            <FormInputLabel data-cy="SSNConfirmLabel" error={isError}>
              Social Security Number (Please re-enter)
            </FormInputLabel>
          </LabelContainer>
          <SSNContainer>
            <InputFull
              disabled={isDisabled || readOnly}
              onChange={(e) => {
                changeSSNField("SSN1Confirm", e.target.value, "3");
              }}
              value={values.SSN1Confirm}
              data-cy="SSN1Confirm"
              id="SSN1Confirm"
              error={!!(errors.SSN1Confirm && touched.SSN1Confirm)}
              onBlur={(e) => {
                blurSSNField("SSN1Confirm", e.target.value);
              }}
              small
              maxLength={3}
            />
            <InputFull
              disabled={isDisabled || readOnly}
              value={values.SSN2Confirm}
              data-cy="SSN2Confirm"
              onChange={(e) => {
                changeSSNField("SSN2Confirm", e.target.value, "2");
              }}
              id="SSN2Confirm"
              error={!!(errors.SSN2Confirm && touched.SSN2Confirm)}
              onBlur={(e) => {
                blurSSNField("SSN2Confirm", e.target.value);
              }}
              small
              maxLength={2}
            />
            <InputFull
              disabled={isDisabled || readOnly}
              value={values.SSN3Confirm}
              data-cy="SSN3Confirm"
              onChange={(e) => {
                changeSSNField("SSN3Confirm", e.target.value, "4");
              }}
              id="SSN3Confirm"
              error={!!(errors.SSN3Confirm && touched.SSN3Confirm)}
              onBlur={(e) => {
                blurSSNField("SSN3Confirm", e.target.value);
              }}
              small
              maxLength={4}
            />
          </SSNContainer>
          {isError && <ErrorLabel>&nbsp;</ErrorLabel>}
        </SSNSection>
      </FormContainer>
    </PersonalInformationFormContainer>
  );
}
