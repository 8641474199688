import styled from "@emotion/styled";
import React, { useState } from "react";
import {
  FormHeader,
  InputFull,
  FormContainer,
  CheckBox,
  FormButtonOutfilled,
  FormButtonfilled,
} from "src/components/forms";
import { UseCenterInfoTypes, UseUserInfoTypes } from "src/hooks/useUserInfo";
import { Link, useHistory } from "react-router-dom";

const TextContainer = styled.span`
  font-size: 13px;
  margin-top: 2em;
`;

const ConfirmationContainer = styled.div`
  align-items: flex-end;
  display: flex;
  padding: 2em 1em 0em;
  flex-direction: column;
  border-radius: 0.3em;
`;

const CheckboxContainer = styled.div`
  align-items: flex-start;
`;

const ButtonContainer = styled.div`
  margin-top: 1em;
  display: flex;
`;

interface SummaryFormTypes {
  isSubmissionMode?: boolean;
  oprCenterInfo: UseCenterInfoTypes;
  userInfo: UseUserInfoTypes;
  isAdminView: boolean;
}

export function OrgSummarySection(props: SummaryFormTypes) {
  const { isSubmissionMode, oprCenterInfo, userInfo, isAdminView } = props;
  const history = useHistory();
  const [accuracyCheck, setAccuracyCheck] = useState(false);
  const [bankAcknowledgement, setBankAcknolwedgement] = useState(false);

  let addressObject;

  if (isAdminView) {
    // Admin queue area renders this block
    addressObject = {
      address1: userInfo.address,
      address2: "",
      city: userInfo.city,
      name: oprCenterInfo.program_name,
      state: userInfo.state,
      zip: userInfo.zipCode,
    };
  } else if (oprCenterInfo.mail_addr1) {
    addressObject = {
      address1: oprCenterInfo.mail_addr1,
      address2:
        oprCenterInfo.mail_addr2 == null ? "" : oprCenterInfo.mail_addr2,
      city: oprCenterInfo.mail_city,
      name: oprCenterInfo.mail_name,
      state: oprCenterInfo.mail_state,
      zip: oprCenterInfo.mail_zip,
    };
  } else {
    addressObject = {
      address1: oprCenterInfo.program_addr_1,
      address2:
        oprCenterInfo.program_addr_2 == null
          ? ""
          : oprCenterInfo.program_addr_2,
      city: oprCenterInfo.program_city,
      name: oprCenterInfo.program_name,
      state: oprCenterInfo.program_state,
      zip: oprCenterInfo.program_zip,
    };
  }
  return (
    <>
      <FormHeader>Organization Dashboard Information</FormHeader>

      <FormContainer>
        <InputFull
          data-cy="SummarySection-Program"
          value={oprCenterInfo.program_name}
          disabled
        >
          Program Name{" "}
        </InputFull>
        <InputFull
          data-cy="SummarySection-License"
          value={oprCenterInfo.license_number}
          disabled
        >
          License Number{" "}
        </InputFull>
      </FormContainer>
      {isSubmissionMode && (
        <>
          <FormContainer>
            <InputFull
              data-cy="SummarySection-Address"
              value={`${oprCenterInfo.program_addr_1} ${oprCenterInfo.program_addr_2}`}
              disabled
            >
              Program Physical Address{" "}
            </InputFull>
            <InputFull
              data-cy="SummarySection-City"
              value={oprCenterInfo.program_city}
              disabled
            >
              City{" "}
            </InputFull>
            <InputFull
              data-cy="SummarySection-State"
              value={oprCenterInfo.program_state}
              disabled
            >
              State{" "}
            </InputFull>
            <InputFull
              data-cy="SummarySection-Zip"
              value={oprCenterInfo.program_zip}
              disabled
            >
              Zip Code{" "}
            </InputFull>
          </FormContainer>
        </>
      )}
      <FormContainer>
        <InputFull
          data-cy="SummarySection-Address"
          value={`${addressObject.address1} ${addressObject.address2}`}
          disabled
        >
          Mailing Address{" "}
        </InputFull>
        <InputFull
          data-cy="SummarySection-City"
          value={addressObject.city}
          disabled
        >
          City{" "}
        </InputFull>
        <InputFull
          data-cy="SummarySection-State"
          value={addressObject.state}
          disabled
        >
          State{" "}
        </InputFull>
        <InputFull
          data-cy="SummarySection-Zip"
          value={addressObject.zip}
          disabled
        >
          Zip Code{" "}
        </InputFull>
      </FormContainer>

      {isSubmissionMode && (
        <FormContainer>
          <TextContainer>
            The information on program physical address and if applicable,
            mailing address, is provided to the OPR from OCLQS. If this
            information is not accurate, you will need to update the information
            in OCLQS before proceeding with completion of the Payment Portal
            entry. Please login to OCLQS to update your account information.
            Please note, if changes are made in OCLQS, updates will not be
            reflected in the OPR for 24 hours. After 24 hours, please return to
            the Organization Dashboard to complete the Payment Portal entry.
          </TextContainer>
        </FormContainer>
      )}
      {isSubmissionMode && (
        <ConfirmationContainer>
          <CheckboxContainer>
            <div>
              <CheckBox
                data-cy="acknowledgementCheckBox"
                checked={accuracyCheck}
                onCheck={(checked) => {
                  setAccuracyCheck(checked);
                }}
              >
                By checking this checkbox I acknowledge that the information for
                program physical address and
                <br /> mailing address, if applicable, are accurate and I am
                ready to enter the Payment Portal.
              </CheckBox>
            </div>
            <CheckBox
              data-cy="acknowledgementCheckBox"
              checked={bankAcknowledgement}
              onCheck={(checked) => {
                setBankAcknolwedgement(checked);
              }}
            >
              <div>
                I understand that I have{" "}
                <u>
                  <b>one opportunity</b>
                </u>{" "}
                to provide this information correctly. <br /> If the banking
                information does not pass validation, I will receive a paper
                check.
              </div>
            </CheckBox>
          </CheckboxContainer>
          <ButtonContainer>
            <FormButtonOutfilled
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel{" "}
            </FormButtonOutfilled>
            <Link to={`/create_request/submission?isOrg=true`}>
              <FormButtonfilled
                disabled={!accuracyCheck || !bankAcknowledgement}
              >
                Start Request{" "}
              </FormButtonfilled>
            </Link>
          </ButtonContainer>
        </ConfirmationContainer>
      )}
    </>
  );
}
