import { useState } from "react";

import { LoaderContainer } from "src/components/Loader";
import styled from "@emotion/styled";
import COLORS from "../colors";
import { Divider } from "..";
import { Export } from "./Export";
import { Import } from "./Import";
import RequestList from "./RequestList";
import Axios from "axios";
import Swal from "sweetalert2";
import { BatchEligibility } from "./BatchEligibility";
import { SystemReports } from "./SystemReports";

interface NavTabTypes {
  selected?: boolean;
}

const NavTab = styled.span`
  padding: 0.2em;
  padding-bottom: 0.6em;
  margin-right: 2em;
  margin-left: 0.2em;
  cursor: pointer;
  color: ${(props: NavTabTypes) =>
    props.selected ? COLORS.BLUE : COLORS.BLACK};
  opacity: ${(props: NavTabTypes) => (props.selected ? 1 : 0.6)};
  border-bottom: ${(props: NavTabTypes) =>
    props.selected ? `1px solid ${COLORS.BLUE}` : "none"};
`;

const LoaderWrapper = styled.div`
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  background-color: ${COLORS.LIGHT_GRAY};
  display: flex;
  opacity: 0.9;
`;

export type RequestRecord = {
  full_name: string;
  status: string;
  opin: string | number;
  submitted_date: string;
  modified_date: string;
  vendor_id: string | number;
  link: string;
  payment_method: string;
};

export function AdminQueue(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const isOrg = params.get("isOrg");

  const onImport = async (reportType: string, file: File) => {
    try {
      setIsLoading(true);

      var formData = new FormData();
      formData.append("file", file!);
      const response = await Axios.post(`/api/${reportType}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Swal.fire("Success", "File has been imported successfully", "success");

      setIsLoading(false);
    } catch (e) {
      Swal.fire(
        "Error",
        "An error has occured while importing the report: " + 
          e?.response?.data?.message ?? e?.response?.data ?? "Unknown Error",
        "error"
      );
      setIsLoading(false);
    }
  };

  const onExportGenerate = async (
    reportType: string,
    dateFrom: string,
    dateTo: string,
    reportTarget: string
  ) => {
    try {
      setIsLoading(true);
      const response = await Axios.get(`/api/${reportType}`, {
        responseType: "arraybuffer",
        params: {
          reportType,
          vendorType: reportTarget,
          dateRange: [dateFrom, dateTo],
        },
      });
      if (response.status === 204) {
        if (reportType == "vendorExport") {
          Swal.fire("No Vendors", "There are no Vendors to report", "warning");
        } else if (reportType == "vendorChanges") {
          Swal.fire(
            "No Vendors",
            "There are no Vendor Changes to report",
            "warning"
          );
        } else {
          Swal.fire(
            "No Invoices",
            "There are no Invoices to report",
            "warning"
          );
        }
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.csv");
        document.body.appendChild(link);
        link.click();
      }
      setIsLoading(false);
    } catch (e) {
      Swal.fire(
        "Error",
        "An error has occured while generating the report",
        "error"
      );
      setIsLoading(false);
    }
  };

  const onBatchInvoices = async (quarter: string) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(`/api/invoiceBatch/${quarter}`, {
        params: {
          quarter,
        },
      });
      if (response.status === 200) {
        Swal.fire(
          "Batch Invoice Job Completed",
          "All applicable invoices have been generated for the specified quarter.",
          "success"
        );
      }
      setIsLoading(false);
    } catch (e) {
      Swal.fire(
        "Error",
        "An error has occured while generating invoices for the specified quarter.",
        "error"
      );
      setIsLoading(false);
    }
  };

  const onSystemReports = async (date: string): Promise<any> => {
    console.log("test");
    try {
      setIsLoading(true);
      const response = await Axios.get(`/api/vendorSummaryReport?date=${date}`);
      setIsLoading(false);
      return response.data;
    } catch (e) {
      Swal.fire(
        "Error",
        "An error has occured while generating invoices for the specified quarter.",
        "error"
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && (
        <LoaderWrapper>
          <LoaderContainer message="Loading..." height="auto" />
        </LoaderWrapper>
      )}
      <div style={{ padding: "2em" }}>
        <h2>Admin Queue</h2>
        <div style={{ margin: "2em 0" }}>
          <NavTab
            selected={selectedTab === 0}
            onClick={() => {
              setSelectedTab(0);
            }}
          >
            All Requests List
          </NavTab>

          <NavTab
            selected={selectedTab === 1}
            onClick={() => {
              setSelectedTab(1);
            }}
          >
            Export
          </NavTab>

          <NavTab
            selected={selectedTab === 2}
            onClick={() => {
              setSelectedTab(2);
            }}
          >
            Import
          </NavTab>

          <NavTab
            selected={selectedTab === 3}
            onClick={() => {
              setSelectedTab(3);
            }}
          >
            Batch Eligibility
          </NavTab>
          <NavTab
            selected={selectedTab === 4}
            onClick={() => {
              setSelectedTab(4);
            }}
          >
            System Reports
          </NavTab>
        </div>
        <Divider />
        {selectedTab === 0 && (
          <div
            style={{
              width: "100%",
              overflow: "scroll",
            }}
          >
            <RequestList isOrg={isOrg} />
          </div>
        )}

        {selectedTab === 1 && <Export onGenerate={onExportGenerate} />}

        {selectedTab === 2 && <Import onImport={onImport} />}

        {selectedTab === 3 && <BatchEligibility onRun={onBatchInvoices} />}

        {selectedTab === 4 && <SystemReports onRun={onSystemReports} />}
      </div>
    </>
  );
}
