// import Yubikey from "./Yubikey.gif";

import styled from "@emotion/styled";

import { Input, Label, InputContainer } from "src/components/forms";
import { RippleButton } from "src/components/buttons";
import { AuthContext } from "src/providers/AuthProvider";
import { useContext } from "react";
import { useFormik } from "formik";

const LoginCard = styled.div`
  background: #f0f0f0;
  border-radius: 0.5em;
  padding: 1.5em;
  display: flex;
  max-width: 500px;
  margin: auto;
  margin-top: 2em;
`;

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export function Login(props: any) {
  const {} = props;
  const auth: any = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      auth.login(values.email, values.password);
    },
  });
  const { handleChange, handleSubmit, values } = formik;
  return (
    <LoginContainer>
      <LoginCard>
        <form onSubmit={handleSubmit}>
          <h2>Sign In</h2>
          <div>
            <ul>
              <li>The user is accessing a restricted information system.</li>
              <li>
                System usage may be monitored, recorded, and subject to audit.
              </li>
              <li>
                Unauthorized use of the system is prohibited and may be subject
                to criminal and/or civil penalties.
              </li>
              <li>
                Use of the system indicates consent to monitoring and recording.
              </li>
            </ul>

            <InputContainer>
              <Label>Email</Label>
              <Input
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
              />
            </InputContainer>
            <InputContainer>
              <Label>Password</Label>
              <Input
                name="password"
                type="password"
                onChange={handleChange}
                value={values.password}
              />
            </InputContainer>
            <RippleButton type="submit">Login</RippleButton>
          </div>
        </form>
      </LoginCard>
    </LoginContainer>
  );
}
