import React, { useEffect, useState, useCallback } from "react";
import "cross-fetch/polyfill";
import * as AWS from "aws-sdk/global";
import { useHistory, useLocation } from "react-router-dom";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import routes from "src/routes";
import axios from "axios";

export const AuthContext = React.createContext({});

const {
  REACT_APP_AWS_REGION: AWS_REGION,
  REACT_APP_COGNITO_CLIENT_ID: COGNITO_CLIENT_ID,
  REACT_APP_COGNITO_USER_POOL_ID: COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_IDENTITY_POOL_ID: COGNITO_IDENTITY_POOL_ID,
} = process.env;

const poolData = {
  UserPoolId: COGNITO_USER_POOL_ID,
  ClientId: COGNITO_CLIENT_ID,
};

const userPool = new CognitoUserPool(poolData);

function getCognitoUser(userName) {
  const poolData = {
    UserPoolId: COGNITO_USER_POOL_ID,
    ClientId: COGNITO_CLIENT_ID,
  };

  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: userName,
    Pool: userPool,
  };

  return new CognitoUser(userData);
}

let cognitoUser;

console.log("authing user");

export function AuthProvider(props) {
  const { children } = props;
  const [auth, setAuth] = useState({});
  const [session, setSession] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useHistory();
  const location = useLocation();
  // const [sessionUserAttributes, setSessionUserAttributes] = useState({});

  const getUserAttributes = useCallback(
    (cognitoUser) => {
      cognitoUser.getUserAttributes((err, result) => {
        if (result) {
          const email = (result.find((x) => x.Name === "email") || {}).Value;
          const userAttributes = {
            email,
          };
          if (
            !session.userAttributes ||
            (session.userAttributes && session.userAttributes.email !== email)
          ) {
            setSession({ ...session, userAttributes });
          }
        }
      });
    },
    [session]
  );

  useEffect(() => {
    cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, result) => {
        if (result) {
          //logged in
          console.log("logged in");
          getUserAttributes(cognitoUser);
          setIsLoggedIn(true);
          if (
            location.pathname === routes.LOGIN ||
            location.pathname === routes.RESET_PASSWORD
          ) {
            history.push(routes.ADMIN + window.location.search);
          }
          AWS.config.region = AWS_REGION;

          const token = result.getAccessToken().getJwtToken();

          axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

          // Add the User's Id Token to the Cognito credentials login map.
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: COGNITO_IDENTITY_POOL_ID,
            Logins: {
              [`cognito-idp.${AWS_REGION}.amazonaws.com/${COGNITO_USER_POOL_ID}`]: result
                .getIdToken()
                .getJwtToken(),
            },
          });
        }
      });
    }
  }, [isLoggedIn, getUserAttributes, history, location]);

  const forgotPassword = useCallback((userName) => {
    const cognitoUser = getCognitoUser(userName);
    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        // successfully initiated reset password request
        console.log("CodeDeliveryData from forgotPassword: " + data);
      },
      onFailure: function (err) {
        alert(err.message || JSON.stringify(err));
      },

      //Optional automatic callback
      inputVerificationCode: function (data) {
        debugger;
        console.log("Code sent to: " + data);
        var verificationCode = document.getElementById("code").value;
        var newPassword = document.getElementById("new_password").value;
        cognitoUser.confirmPassword(verificationCode, newPassword, {
          onSuccess() {
            console.log("Password confirmed!");
          },
          onFailure(err) {
            console.log("Password not confirmed!");
          },
        });
      },
    });
  }, []);

  const login = useCallback(
    (email, password) => {
      const authenticationData = {
        Email: email,
        Password: password,
      };

      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );

      let cognitoUser = getCognitoUser(email);

      setSession({ ...session, cognitoUser });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          // const accessToken = result.getAccessToken().getJwtToken();
          //POTENTIAL: Region needs to be set if not already set previously elsewhere.
          AWS.config.region = AWS_REGION;
          const loginUrl = `cognito-idp.${AWS_REGION}.amazonaws.com/${COGNITO_USER_POOL_ID}`;

          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: COGNITO_IDENTITY_POOL_ID, // your identity pool id here
            Logins: {
              // Change the key below according to the specific region your user pool is in.
              [loginUrl]: result.getIdToken().getJwtToken(),
            },
          });
          setIsLoggedIn(true);
          getUserAttributes(cognitoUser);
          history.push(routes.ADMIN + window.location.search);
          //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
          AWS.config.credentials.refresh((error) => {
            if (error) {
              console.error(error);
            } else {
              // Instantiate aws sdk service objects now that the credentials have been updated.
              // example: var s3 = new AWS.S3();
              console.log("Successfully logged in!");
            }
          });
        },
        mfaSetup: function (challengeName, challengeParameters) {
          cognitoUser.associateSoftwareToken(this);
        },
        mfaRequired: function (codeDeliveryDetails) {
          var verificationCode = prompt("Please input verification code", "");
          cognitoUser.sendMFACode(verificationCode, this);
        },
        totpRequired: function (secretCode) {
          var challengeAnswer = prompt("Please input the TOTP code.", "");
          cognitoUser.sendMFACode(challengeAnswer, this, "SOFTWARE_TOKEN_MFA");
        },
        associateSecretCode: function (secretCode) {
          alert(`Use this code to add MFA TOTP Account: ${secretCode}`);
          const challengeAnswer = prompt("Please input the TOTP code.", "");
          cognitoUser.verifySoftwareToken(
            challengeAnswer,
            "My TOTP device",
            this
          );
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          delete userAttributes.email_verified;

          // store userAttributes on global variable
          setSession({ ...session, userAttributes, cognitoUser });

          if (location.pathname !== "/reset-password") {
            history.push("/reset-password" + window.location.search);
          }
        },
        onFailure: function (err) {
          setIsLoggedIn(false);
          alert(err.message || JSON.stringify(err));
        },
      });
    },
    [getUserAttributes, history, location, session]
  );

  const resetPassword = useCallback(
    (email, newPassword) => {
      const { userAttributes, cognitoUser } = session;

      if (cognitoUser) {
        cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes);
        setSession({ userAttributes });
        history.push(routes.LOGIN + window.location.search);
      }
    },
    [session]
  );

  const logout = useCallback(() => {
    cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }
    setSession({});
    setIsLoggedIn(false);
    history.push(routes.LOGIN + window.location.search);
  }, [history]);

  useEffect(() => {
    setAuth({
      resetPassword,
      login,
      logout,
      isLoggedIn,
      userAttributes: session.userAttributes,
    });
  }, [resetPassword, login, logout, isLoggedIn, session.userAttributes]);

  return (
    <AuthContext.Provider value={{ ...auth, axios }}>
      {children}
    </AuthContext.Provider>
  );
}
