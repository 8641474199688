// import Yubikey from "./Yubikey.gif";
import styled from "@emotion/styled";
import COLORS from "./colors";
import { FormTitle, InformationalHeader } from "src/components/forms";
import { useHistory } from "react-router-dom";

const Body = styled.div`
  display: flex;
  flex: 1;
  padding: 2em 1em;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 0.7;
  padding: 2em 1em;
  flex-direction: column;
  border: 1px solid ${COLORS.LIGHT_GRAY};
  border-radius: 0.3em;
`;

const Logo = styled.img`
  position: absolute;
  right: 0;
  top: 5px;
`;

export default function InProgressMessage() {
  const history = useHistory();
  return (
    <Body>
      <Container>
        <Logo
          height={100}
          src={require("../assets/icons/security-ssl.svg").default}
        />
        <div>
          <FormTitle>Previous update is still being processed</FormTitle>
        </div>
        <div>
          <InformationalHeader>This may take several days.</InformationalHeader>
          <br />
          <a href="#" onClick={() => history.goBack()}>
            Go Back
          </a>
        </div>
      </Container>
    </Body>
  );
}
