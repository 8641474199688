import styled from "@emotion/styled";
import { FormHeader, Divider, CheckBox } from "src/components/forms";
import { useState } from "react";
const ReasonCodeSectioContainer = styled.div`
  flex-direction: column;
`;




interface ReasonCodeSectionType {
  onReasonCodeSelection: React.Dispatch<React.SetStateAction<{
    personalInfo: boolean;
    bankingInfo: boolean;
  }>>
}

export function ReasonCodeSection(props: ReasonCodeSectionType) {
  const {onReasonCodeSelection} = props;
  const [personalInformationChecked, setPersonalInformationChecked] = useState(false)
  const [bankingInformationChecked, setBankingInformationChecked] = useState(false)
  return (
    <ReasonCodeSectioContainer>
      <Divider />
      <FormHeader>Reason Code</FormHeader>
      <CheckBox 
      checked={personalInformationChecked}
      onCheck={(checked)=>{
        setPersonalInformationChecked(checked)
        onReasonCodeSelection({
          personalInfo: checked,
          bankingInfo: bankingInformationChecked
        })
      }}>Edit Personal Information</CheckBox>
      <CheckBox 
      checked={bankingInformationChecked}
      onCheck={(checked)=>{
        setBankingInformationChecked(checked)
        onReasonCodeSelection({
          personalInfo: personalInformationChecked,
          bankingInfo: checked
        })
      }}>Edit Banking Information</CheckBox>
    </ReasonCodeSectioContainer>
  );
}
