import styled from "@emotion/styled";
import React from "react";
import { FormButtonfilled } from "src/components/forms";

import Modal from "react-modal";
import COLORS from "./colors";
import { FaUserLock, FaTimes } from "react-icons/fa";

const ModalButtonContainer = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: center;
`;

const ModalCloseButton = styled.span`
  &:hover {
    opacity: 0.6;
  }
  &:active {
    top: 1px;
    left: 1px;
  }
  position: relative;
  cursor: pointer;
`;

const ConfirmationModalContainer = styled.div`
  border: 1px solid ${COLORS.BLUE};
  color: ${COLORS.BLACK};
  padding-bottom: 1.2em;
  border-radius: 4px;
`;

const ConfirmationModalText = styled.div`
  text-align: center;
  margin-bottom: 2em;
  padding: 0 3em;
  font-size: 0.8em;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.4em;
  font-size: 1.5em;
  color: ${COLORS.BLACK};
  padding: 0.5em 0.5em;
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "90%",
    transform: "translate(-50%, -50%)",
    padding: "0",
  },
};

interface ConfirmationModalTypes {
  isOrg: boolean;
  isOpen: boolean;
  date: string;
  time: string;
  checkRequested: string;
  requestID: string;
  onClose?(): void;
  onOk?(): void;
  revertedToCheck: boolean;
  confirmationMessage: string;
}

export function ConfirmationModal(props: ConfirmationModalTypes) {
  const {
    date,
    time,
    requestID,
    isOpen,
    onClose,
    onOk,
    isOrg,
    checkRequested,
    revertedToCheck,
    confirmationMessage,
  } = props;
  return (
    <>
      <Modal style={customStyles} isOpen={isOpen}>
        <ConfirmationModalContainer>
          <ModalHeader>
            <FaUserLock />
            <ModalCloseButton
              onClick={() => {
                onClose && onClose();
              }}
            >
              <FaTimes />
            </ModalCloseButton>
          </ModalHeader>
          {!revertedToCheck && (
            <ConfirmationModalText>
              Your request has been submitted successfully on {date} at {time}{" "}
              with request ID {requestID}.
            </ConfirmationModalText>
          )}
          {!checkRequested && !revertedToCheck && (
            <ConfirmationModalText>
              Based on the information entered by the professional, your account
              has been verified and the ACH submission is successful.
            </ConfirmationModalText>
          )}
          {!!checkRequested && (
            <ConfirmationModalText>
              Based on the information entered by the professional, your payment
              will be processed as a check
            </ConfirmationModalText>
          )}
          {!checkRequested && revertedToCheck && confirmationMessage && (
            <ConfirmationModalText
              dangerouslySetInnerHTML={{ __html: confirmationMessage }}
            />
          )}
          {isOrg ? (
            <ConfirmationModalText>
              <b>
                Please keep this information for your records and check the
                Organization Dashboard for payment status.
              </b>
            </ConfirmationModalText>
          ) : (
            <ConfirmationModalText>
              <b>
                Please keep this information for your records and check the Hero
                Pay tab in your OPR profile for payment status.
              </b>
            </ConfirmationModalText>
          )}
          <ModalButtonContainer>
            <FormButtonfilled
              onClick={() => {
                onOk && onOk();
              }}
            >
              OK{" "}
            </FormButtonfilled>
          </ModalButtonContainer>
        </ConfirmationModalContainer>
      </Modal>
    </>
  );
}
