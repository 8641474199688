const COLORS = {
    RED: 'red',
    BLUE: '#3D40BE',
    LIGHT_GRAY: '#EAEAEA',
    GRAY: '#7C7C7C',
    LIGHT_BLUE: '#A1A4CF',
    WHITE: '#FFFFFF',
    BLACK: '#000000'
}

export default COLORS