import styled from "@emotion/styled";

const StyledFooter = styled.h1`
  background-color: #052b4b;
  font-size: 24px;
  padding: 0.5em;
  margin: 0;
  height: 2em;
`;

export function Footer() {
  return <StyledFooter>&nbsp;</StyledFooter>;
}
