// import Yubikey from "./Yubikey.gif";
import styled from "@emotion/styled";

import { SummarySection } from "./formSections/SummarySection";
import { OrgSummarySection } from "./formSections/orgForms/OrgSummarySection";
import COLORS from "./colors";

import {
  FormTitle,
  InformationalHeader,
  ReviewHeaderLarge,
} from "src/components/forms";
import { FaUserLock } from "react-icons/fa";
import { UseCenterInfoTypes, UseUserInfoTypes } from "src/hooks/useUserInfo";

const Body = styled.div`
  display: flex;
  flex: 1;
  padding: 2em 1em;
  justify-content: center;
`;

const HeroSummaryFormContainer = styled.div`
  position: relative;
  display: flex;
  flex: 0.7;
  padding: 2em 1em;
  flex-direction: column;
  border: 1px solid ${COLORS.LIGHT_GRAY};
  border-radius: 0.3em;
`;

const Logo = styled.img`
  position: absolute;
  right: 0;
  top: 5px;
`;

interface HeroSummaryFormTypes {
  userInfo: UseUserInfoTypes;
  oprCenterInfo: UseCenterInfoTypes;
  isOrg: boolean;
}

export function HeroSummaryForm(props: HeroSummaryFormTypes) {
  const { userInfo, isOrg, oprCenterInfo } = props;
  const isAdminView = false; // Summary form is never usd in Admin view
  return (
    <Body>
      <HeroSummaryFormContainer>
        <Logo
          height={100}
          src={require("../assets/icons/security-ssl.svg").default}
        />
        <div>
          <FormTitle>
            {isOrg ? "Payment Portal" : "Payment Portal - HERO Summary Form"}
          </FormTitle>
        </div>
        <div>
          <InformationalHeader>
            {isOrg
              ? "You have entered OCCRRA's secure Payment Portal."
              : "You have entered a secure Payment Portal used for processing Hero Pay."}
            &nbsp; &nbsp;
            <FaUserLock size="3em" />{" "}
          </InformationalHeader>
        </div>
        <div>
          <ReviewHeaderLarge>
            {isOrg
              ? "Please review the information below for accuracy."
              : `Please review the information below for accuracy. If any information
            presented is not accurate, please select the Edit Profile button,
            update your OPR profile and re-enter the Hero Pay Payment Portal. Name needs to match
            banking information. If your payment is a check, it will be mailed to the address below.`}
          </ReviewHeaderLarge>
        </div>
        {isOrg ? (
          <OrgSummarySection
            isSubmissionMode
            userInfo={userInfo}
            oprCenterInfo={oprCenterInfo}
            isAdminView={isAdminView}
          />
        ) : (
          <SummarySection isSubmissionMode userInfo={userInfo} />
        )}
      </HeroSummaryFormContainer>
    </Body>
  );
}
