import React from "react";

import {
  FormCheckboxContainer,
  FormCheckBox,
  FormCheckBoxLabel,
  FormSubCheckBoxLabel,
} from ".";

interface ICheckBox {
  children?: React.ReactNode;
  subLabel?: string;
  disabled?: boolean;
  checked?: boolean;
  onCheck?(e: any): void;
  reverse?: boolean;
  checkBoxSize?: string;
  "data-cy"?: string;
  labelStyle?: any;
  containerStyle?: any;
  checkboxStyle?: any;
  weight?: any;
  flex?: string;
}

export const CheckBox = (props: ICheckBox) => {
  const {
    children,
    subLabel,
    disabled,
    checked,
    onCheck,
    reverse,
    checkBoxSize,
    labelStyle,
    containerStyle,
    checkboxStyle,
    weight,
  } = props;
  return (
    <>
      <FormCheckboxContainer
        style={containerStyle}
        data-cy={props["data-cy"]}
        reverse={reverse}
      >
        <FormCheckBox
          style={checkboxStyle}
          checkBoxSize={checkBoxSize}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onCheck && onCheck(e.target.checked);
          }}
          checked={checked}
          disabled={disabled}
          type="checkbox"
        />

        <FormCheckBoxLabel weight={weight} style={labelStyle} reverse={reverse}>
          {children}
        </FormCheckBoxLabel>
      </FormCheckboxContainer>
      {subLabel && <FormSubCheckBoxLabel>{subLabel}</FormSubCheckBoxLabel>}
    </>
  );
};
