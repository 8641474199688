import styled from "@emotion/styled";
import { values } from "cypress/types/lodash";
import { FormikProps } from "formik";
import React, { useState } from "react";
import {
  InputFull,
  FormHeader,
  Divider,
  FormInputLabel,
  CheckBox,
} from "src/components/forms";
import { InputRow } from "../../forms/InputRow";
import { FormikInitValues } from "../../HeroSubmissionForm";

const RadioButton = styled.input`
  margin-bottom: 0.1em;
  margin-right: 0.7em;
  height: 1.5em;
  margin-left: 0;
`;

interface BankAccountOwnerInformationSectionTypes {
  isDisabled?: boolean;
  onBankAvailableChange(value: boolean): void;
  setIsCorp: any;
  setHasBanking: any;
}

export function BankAccountOwnerInformationSection(
  props: FormikProps<FormikInitValues> & BankAccountOwnerInformationSectionTypes
) {
  const {
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    isDisabled,
    setIsCorp,
    values,
    setHasBanking,
  } = props;

  const radiobuttonSelected = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    setFieldValue("accountType", selectedValue, true);
    setFieldTouched("accountType", true, false);
  };

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const value = e.target.value.trim();
    setFieldValue(field, value, true);
    setFieldTouched(field, true, false);
  };

  const accountTypeError = !!(errors.accountType && touched.accountType);
  const routingError = !!(
    (errors.routingNumber && touched.routingNumber) ||
    (errors.routingNumberConfirm && touched.routingNumberConfirm)
  );
  const accountNumberError = !!(
    (errors.accountNumber && touched.accountNumber) ||
    (errors.accountNumberConfirm && touched.accountNumberConfirm)
  );

  return (
    <>
      <Divider />
      <FormHeader>Bank Account Owner Information</FormHeader>

      <InputRow
        rowText="Please Select Account Type:"
        rows={[
          <FormInputLabel
            data-cy="bankingInformationAccountTypeCheckingLabel"
            error={accountTypeError}
          >
            <RadioButton
              disabled={!!values.noBankAccountAvailable || isDisabled}
              data-cy="bankingInformationAccountTypeCheckingRadio"
              onClick={radiobuttonSelected}
              type="radio"
              name="bankAccount"
              value="checking"
              checked={values.accountType === "checking"}
            />{" "}
            Checking Account
          </FormInputLabel>,
          <FormInputLabel
            data-cy="bankingInformationAccountTypeSavingsLabel"
            error={accountTypeError}
          >
            <RadioButton
              disabled={!!values.noBankAccountAvailable || isDisabled}
              data-cy="bankingInformationAccountTypeSavingsRadio"
              onClick={radiobuttonSelected}
              type="radio"
              name="bankAccount"
              value="savings"
              checked={values.accountType === "savings"}
            />
            Savings Account
          </FormInputLabel>,
        ]}
        errorText={errors.accountType}
        isError={accountTypeError}
      />
      <InputRow
        rowText="Please enter your name as it is shown in your bank statements."
        rows={[
          <InputFull
            disabled
            value={values.EINName || values.SSNName}
            data-cy="bankingInformationPayeeName"
          >
            Payee Name
          </InputFull>,
          <div />,
        ]}
      />
      <InputRow
        errorLabelCypressLabel="bankingInformationRoutingErrorLabel"
        rowText="Routing Number: Please carefully enter the nine digit routing. Usually listed on the bottom of a personal check."
        rows={[
          <InputFull
            value={values.routingNumber}
            disabled={!!values.noBankAccountAvailable || isDisabled}
            data-cy="bankingInformationRoutingNumber"
            error={!!(errors.routingNumber && touched.routingNumber)}
            onChange={(e) => {
              setFieldValue("routingNumber", e.target.value, false);
            }}
            onBlur={(e) => {
              onInputChange(e, "routingNumber");
            }}
          >
            Routing Number
          </InputFull>,
          <InputFull
            value={values.routingNumberConfirm}
            disabled={!!values.noBankAccountAvailable || isDisabled}
            data-cy="bankingInformationRoutingNumberConfirmation"
            error={
              !!(errors.routingNumberConfirm && touched.routingNumberConfirm)
            }
            onChange={(e) => {
              setFieldValue("routingNumberConfirm", e.target.value, false);
            }}
            onBlur={(e) => {
              onInputChange(e, "routingNumberConfirm");
            }}
          >
            Routing Number (Please re-enter)
          </InputFull>,
        ]}
        errorText={
          (errors.routingNumber && touched.routingNumber
            ? errors.routingNumber
            : "") ||
          (errors.routingNumberConfirm && touched.routingNumberConfirm
            ? errors.routingNumberConfirm
            : "")
        }
        isError={routingError}
      />
      <InputRow
        errorLabelCypressLabel="bankingInformationAccountErrorLabel"
        rowText="Account Number: Please carefully enter your bank account number including leading zeros."
        rows={[
          <InputFull
            value={values.accountNumber}
            disabled={!!values.noBankAccountAvailable || isDisabled}
            data-cy="bankingInformationAccountNumber"
            error={!!(errors.accountNumber && touched.accountNumber)}
            onChange={(e) => {
              setFieldValue("accountNumber", e.target.value, false);
            }}
            onBlur={(e) => {
              onInputChange(e, "accountNumber");
            }}
          >
            Account Number
          </InputFull>,
          <InputFull
            value={values.accountNumberConfirm}
            disabled={!!values.noBankAccountAvailable || isDisabled}
            data-cy="bankingInformationAccountNumberConfirmation"
            error={
              !!(errors.accountNumberConfirm && touched.accountNumberConfirm)
            }
            onChange={(e) => {
              setFieldValue("accountNumberConfirm", e.target.value, false);
            }}
            onBlur={(e) => {
              onInputChange(e, "accountNumberConfirm");
            }}
          >
            Account Number (Please re-enter)
          </InputFull>,
        ]}
        errorText={
          (errors.accountNumber && touched.accountNumber
            ? errors.accountNumber
            : "") ||
          (errors.accountNumberConfirm && touched.accountNumberConfirm
            ? errors.accountNumberConfirm
            : "")
        }
        isError={accountNumberError}
      />
      <InputRow
        errorLabelCypressLabel="bankingInformationAccountErrorLabel"
        flex={"1"}
        endAlign
        width={"65%"}
        rows={[
          <CheckBox
            weight={"light"}
            checkBoxSize="unset"
            onCheck={(value) => {
              //HERE
              setFieldValue("payeeName", "");
              setFieldTouched("payeeName", false);
              setFieldValue("routingNumber", "");
              setFieldTouched("routingNumber", false);
              setFieldValue("routingNumberConfirm", "");
              setFieldTouched("routingNumberConfirm", false);
              setFieldValue("accountNumber", "");
              setFieldTouched("accountNumber", false);
              setFieldValue("accountNumberConfirm", "");
              setFieldTouched("accountNumberConfirm", false);
              if (value) {
                setFieldValue("accountType", false);
                setFieldTouched("accountType", false);
              } else {
                setFieldValue("accountType", "", true);
                setFieldTouched("accountType", false);
              }
              setFieldValue("noBankAccountAvailable", value, true);
              setFieldValue("requestCheck", value);
              setHasBanking(value);
            }}
          >
            No bank account available. (If selected, the payment will be made
            via check)
          </CheckBox>,
        ]}
      />
      <InputRow
        flex={"1"}
        errorLabelCypressLabel="bankingInformationAccountErrorLabel"
        endAlign
        width={"65%"}
        rows={[
          <CheckBox
            flex={"1"}
            weight={"light"}
            checkBoxSize="unset"
            onCheck={(value) => {
              setFieldValue("repName", "", false);
              setFieldTouched("repName", false);
              setFieldValue("repEmail", "", false);
              setFieldTouched("repEmail", false);
              setIsCorp(value);
              setFieldValue("corpAccount", value);
            }}
          >
            This organization is tied to a corporate account. I need to add a
            fiscal representative for notification regarding payments
          </CheckBox>,
        ]}
      />

      <InputRow
        errorLabelCypressLabel="bankingInformationAccountErrorLabel"
        rowText=" "
        rows={[
          <InputFull
            value={values.repName}
            disabled={!values.corpAccount}
            data-cy="bankingInformationAccountNumber"
            error={!!(errors.repName && touched.repName)}
            onChange={(e) => {
              setFieldValue("repName", e.target.value, false);
            }}
            onBlur={(e) => {
              onInputChange(e, "repName");
            }}
          >
            Fiscal Representative Name
          </InputFull>,
          <InputFull
            value={values.repEmail}
            disabled={!values.corpAccount}
            data-cy="bankingInformationAccountNumberConfirmation"
            error={!!(errors.repEmail && touched.repEmail)}
            onChange={(e) => {
              setFieldValue("repEmail", e.target.value, false);
            }}
            onBlur={(e) => {
              onInputChange(e, "repEmail");
            }}
          >
            Fiscal Representative Email
          </InputFull>,
        ]}
        errorText={
          (errors.repEmail && touched.repEmail ? errors.repEmail : "") ||
          (errors.repName && touched.repName ? errors.repName : "")
        }
        isError={
          (!!errors.repEmail && touched.repEmail) ||
          (!!errors.repName && touched.repName)
        }
      />
    </>
  );
}
