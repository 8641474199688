import { useEffect, useState } from "react";

import Axios from "axios";

import { DatePickerInput, FormButtonfilled, FormInputLabel } from "..";
import { optionsTypes, SelectInput } from "../forms/SelectInput";
import styled from "@emotion/styled";
import COLORS from "../colors";
import moment from "moment";

interface RequestListTypes {
  onRun(invoiceId: string): any;
}

interface ItableData {
  professionalsCheck: string;
  organizationsCheck: string;
  professionalsEFT: string;
  organizationsEFT: string;
}

interface QuarterType {
  id: string;
  description: string;
}

const Table = styled.table`
  border: 1px solid ${COLORS.BLACK};
  margin-left: 2em;
  border-collapse: collapse;
  th {
    background-color: ${COLORS.LIGHT_GRAY};
    border: 1px solid ${COLORS.BLACK};
    padding: 0.5em 3em;
  }
  td {
    border: 1px solid ${COLORS.BLACK};
    padding: 0.5em 2em;
  }
  tr {
    border: 1px solid ${COLORS.BLACK};
  }
  tr td:first-child {
    background-color: ${COLORS.LIGHT_GRAY};
  }
`;

export function SystemReports(props: RequestListTypes) {
  const { onRun } = props;

  const [dateTo, setDateTo] = useState<string | null>(null);
  const [tableData, setTableData] = useState<ItableData | null>(null);

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ width: "25em" }}>
          <div style={{ flex: 1 }}>
            <DatePickerInput
              defaultDate={dateTo ? new Date(dateTo) : null}
              name="toDate"
              onCalendarClose={() => {}}
              isError={false}
              onChange={(date) => {
                const parsedDate = date
                  ? new Date(Array.isArray(date) ? date[0] : date)
                      .toLocaleDateString("en-US")
                      .slice(0, 10)
                  : "";
                if (!date) {
                  setTableData(null);
                }
                setDateTo(parsedDate);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 1,
              marginTop: "1em",
            }}
          >
            <FormButtonfilled
              disabled={!dateTo}
              onClick={async () => {
                setTableData(null);
                if (dateTo) {
                  const response = await onRun(
                    moment(new Date(dateTo).toISOString()).format("YYYY-MM-DD")
                  );
                  setTableData({
                    professionalsCheck:
                      (response[0] && response[0].count) || "0",
                    organizationsCheck:
                      (response[3] && response[3].count) || "0",
                    professionalsEFT: (response[1] && response[1].count) || "0",
                    organizationsEFT: (response[4] && response[4].count) || "0",
                  });
                }
              }}
            >
              Generate Report
            </FormButtonfilled>
          </div>
        </div>
        {tableData && (
          <Table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Professionals</th>
                <th>Organizations</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Check</td>
                <td>{tableData?.professionalsCheck}</td>
                <td>{tableData?.organizationsCheck}</td>
              </tr>
              <tr>
                <td>EFT</td>
                <td>{tableData?.professionalsEFT}</td>
                <td>{tableData?.organizationsEFT}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
}
