import styled from "@emotion/styled";
import React from "react";
import { ErrorLabel, InformationalHeader } from ".";

interface InputRowContainerType {
  endAlign?: boolean;
  width?: string;
}

const InputRowContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-self: ${(props: InputRowContainerType) =>
    props.endAlign ? "end" : "unset"};
  width: ${(props: InputRowContainerType) =>
    props.width ? props.width : "unset"};
`;

interface IRowContainer {
  flex?: string;
}

const RowContainer = styled.div`
  display: flex;
  flex: ${(props: IRowContainer) => (props.flex ? props.flex : "0.5")};
  align-items: flex-end;
`;

interface RowContainerTextType {
  labelPadding?: string;
}

const RowContainerText = styled.div`
  display: flex;
  flex: 0.5;
  padding-right: ${(props: RowContainerTextType) =>
    props.labelPadding ? props.labelPadding : "2em"};
  align-items: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0.2;
`;

const ErrorStyle = styled.div`
  display: flex;
  flex: 0.5;
  justify-content: center;
`;

interface InputRowType {
  rows?: React.ReactNode[];
  rowText?: React.ReactNode;
  errorText?: string;
  isError?: boolean;
  errorLabelCypressLabel?: string;
  boldText?: boolean;
  labelPadding?: string;
  hideError?: boolean;
  flex?: string;
  endAlign?: boolean;
  width?: string;
}

export const InputRow = (props: InputRowType) => {
  const {
    rows,
    rowText,
    errorText,
    isError,
    errorLabelCypressLabel,
    boldText,
    labelPadding,
    hideError,
    flex,
    endAlign,
    width,
  } = props;
  return (
    <>
      <InputRowContainer width={width} endAlign={endAlign}>
        {rowText && (
          <RowContainerText labelPadding={labelPadding}>
            <InformationalHeader error={isError} noMargin boldText={boldText}>
              {rowText}
            </InformationalHeader>
          </RowContainerText>
        )}
        {rows &&
          rows.map((Row: React.ReactNode, index: number) => {
            return <RowContainer flex={flex}>{Row}</RowContainer>;
          })}
      </InputRowContainer>
      {!hideError && (
        <ErrorContainer>
          <ErrorStyle>
            <ErrorLabel data-cy={errorLabelCypressLabel}>
              {" "}
              {isError ? errorText : " "}{" "}
            </ErrorLabel>
          </ErrorStyle>
        </ErrorContainer>
      )}
    </>
  );
};
