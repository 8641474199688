import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import routes from "src/routes";
import { AuthProvider, AuthContext } from "./providers/AuthProvider";
import styled from "@emotion/styled";
import {
  Login,
  CreateNewPassword,
  Header,
  Footer,
  HeroSubmissionForm,
  HeroSummaryForm,
  AdminQueue,
} from "src/components";

import useUserInfo from "./hooks/useUserInfo";
import ErrorMessage from "./components/ErrorMessage";
import InProgressMessage from "./components/InProgressMessage";
import { LoaderContainer } from "src/components/Loader";
import { useContext } from "react";
require("dotenv").config();

const RouteContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Spacer = styled.div`
  flex: auto;
`;

function PrivateRoute({ children, ...rest }) {
  const auth = useContext(AuthContext);
  const { isLoggedIn } = auth;

  if (isLoggedIn) {
    return <Route {...rest}>{children}</Route>;
  } else {
    return <Redirect to={routes.LOGIN} />;
  }
}

function getIsOrg() {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  return params.get("isOrg");
}

function App() {
  const [userInfoLoading, userInfoError, userInfo, oprCenterInfo] = useUserInfo(
    !!getIsOrg()
  );

  return (
    <Router>
      <AuthProvider>
        <RouteContainer>
          <Header />
          <Switch>
            <Route path={routes.LOGIN} component={Login}></Route>
            <Route
              path={routes.RESET_PASSWORD}
              component={CreateNewPassword}
            ></Route>
            <PrivateRoute
              path={routes.ADMIN}
              component={AdminQueue}
            ></PrivateRoute>
            <Route
              exact
              path={routes.HERO_SUMMARY}
              render={(props) => {
                if (userInfoLoading) {
                  return (
                    <LoaderContainer
                      data-cy="loader"
                      message="Loading Summary Form..."
                    />
                  );
                }
                if (userInfo.submissionInProgress) {
                  return <InProgressMessage />;
                }
                if (userInfoError) {
                  return <ErrorMessage />;
                }
                return (
                  <HeroSummaryForm
                    isOrg={!!getIsOrg()}
                    {...props}
                    userInfo={userInfo}
                    oprCenterInfo={oprCenterInfo}
                  />
                );
              }}
            ></Route>
            <Route
              path={routes.CREATE_HERO_SUBMISSION}
              render={(props) => {
                if (userInfoLoading) {
                  return (
                    <LoaderContainer
                      data-cy="loader"
                      message="Loading Submission Form..."
                    />
                  );
                }
                if (userInfo.submissionInProgress) {
                  return <InProgressMessage />;
                }
                if (userInfoError) {
                  return <ErrorMessage />;
                }
                return (
                  <HeroSubmissionForm
                    {...props}
                    isOrg={!!getIsOrg()}
                    showSummarySection
                    showPersonalSection
                    showBankAccountSection
                    showSignatureSection
                    userInfo={userInfo}
                    oprCenterInfo={oprCenterInfo}
                  />
                );
              }}
            ></Route>
            <PrivateRoute
              isOrg={!!getIsOrg()}
              path={routes.ADMIN_HERO_SUBMISSION}
              render={(props) => {
                const urlParams = new URLSearchParams(window.location.search);
                let paramObj = {};
                for (var value of urlParams.keys()) {
                  paramObj[value] = urlParams.get(value);
                }
                return (
                  <HeroSubmissionForm
                    {...props}
                    showSummarySection
                    showSignatureSection={{
                      hideAllButtons: true,
                      disableFields: true,
                    }}
                    showAdminSection
                    userInfo={paramObj}
                    oprCenterInfo={paramObj}
                    isOrg={!!getIsOrg()}
                    isAdminView
                  />
                );
              }}
            ></PrivateRoute>
            <PrivateRoute
              isOrg={!!getIsOrg()}
              path={routes.W9_VIEW}
              render={(props) => {
                const urlParams = new URLSearchParams(window.location.search);
                let paramObj = {};
                for (var value of urlParams.keys()) {
                  paramObj[value] = urlParams.get(value);
                }
                return (
                  <HeroSubmissionForm
                    {...props}
                    showSummarySection
                    showPersonalSection
                    showSignatureSection={{
                      hideAllButtons: true,
                      disableFields: true,
                    }}
                    showGoBackButton
                    userInfo={paramObj}
                    isOrg={!!getIsOrg()}
                    isW9
                    oprCenterInfo={paramObj}
                  />
                );
              }}
            ></PrivateRoute>
            <Route
              path={routes.EDIT_HERO_SUBMISSION}
              render={(props) => {
                if (userInfoLoading) {
                  return (
                    <LoaderContainer
                      data-cy="loader"
                      message="Loading Submission Form..."
                    />
                  );
                }
                if (userInfoError) {
                  return <ErrorMessage />;
                }
                return (
                  <HeroSubmissionForm
                    {...props}
                    showSummarySection
                    showBankAccountSection
                    showPersonalSection
                    showSignatureSection
                    userInfo={userInfo}
                    oprCenterInfo={oprCenterInfo}
                    isOrg={!!getIsOrg()}
                  />
                );
              }}
            ></Route>
            <PrivateRoute>
              <div>404 - Are you lost?</div>
            </PrivateRoute>
          </Switch>
          <Spacer />
          <Footer />
        </RouteContainer>
      </AuthProvider>
    </Router>
  );
}

export default App;
