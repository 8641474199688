import {
  selectColumnFilter,
  StandardTable,
  DatePickerFilter,
  DatePickerFilter2,
  SelectOption,
  displaySelectCell,
  displayDateCell,
} from "src/components/StandardTable";
import { useEffect, useState, useCallback, cloneElement } from "react";

import Axios from "axios";

import { useAdminQueue } from "src/hooks";
import { LoaderContainer } from "src/components/Loader";
import styled from "@emotion/styled";
import COLORS from "../colors";
import { DatePickerInput, Divider, FormButtonfilled, FormInputLabel } from "..";

interface NavTabTypes {
  selected?: boolean;
}

const NavTab = styled.span`
  padding: 0.2em;
  padding-bottom: 0.6em;
  margin-right: 2em;
  margin-left: 0.2em;
  cursor: pointer;
  color: ${(props: NavTabTypes) =>
    props.selected ? COLORS.BLUE : COLORS.BLACK};
  opacity: ${(props: NavTabTypes) => (props.selected ? 1 : 0.6)};
  border-bottom: ${(props: NavTabTypes) =>
    props.selected ? `1px solid ${COLORS.BLUE}` : "none"};
`;

const SmallDivider = styled.span`
  align-self: center;
  justify-self: center;
  flex: 0.3;
  text-align: center;
  padding-right: 0.6em;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  margin: 0.7em 0;
  align-items: center;
`;

const RadioButton = styled.input`
  margin-bottom: 0.1em;
  margin-right: 0.7em;
  height: 1.5em;
  width: 1.5em;
  margin-left: 0;
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface RequestListTypes {
  onImport(reportType: string, file: File | null): void;
}

export function Import(props: RequestListTypes) {
  const { onImport } = props;
  const [reportType, setReportType] = useState("vendorImport");
  const [file, setFile] = useState<File | null>(null);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ marginRight: "4em" }}>
          <FormInputLabel>Please select the report to import:</FormInputLabel>
          <RadioButtonContainer>
            <RadioButton
              defaultChecked
              type="radio"
              onChange={() => {
                setReportType("vendorImport");
              }}
              name="reportName"
            />{" "}
            Vendor Import Report
          </RadioButtonContainer>
          <RadioButtonContainer>
            <RadioButton
              type="radio"
              name="reportName"
              onChange={() => {
                setReportType("invoiceImport");
              }}
            />
            Invoice Import Report
          </RadioButtonContainer>
        </div>

        <div>
          <FormInputLabel
            style={{
              color: COLORS.BLACK,
              fontWeight: 400,
              fontStyle: "italic",
            }}
          >
            Please make sure that the supplied CSV file for
            <br />
            importing has the required column names.
          </FormInputLabel>
          <input
            type="file"
            onChange={(e) => {
              if (e.target.files) {
                setFile(e.target.files[0]);
              }
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 1,
              marginTop: "1em",
            }}
          >
            <FormButtonfilled
              disabled={!file}
              onClick={() => {
                onImport(reportType, file);
              }}
            >
              Import Report
            </FormButtonfilled>
          </div>
        </div>
      </div>
    </>
  );
}
