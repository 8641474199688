import React from "react";
export const LoaderBall = ({ color = "#fff" }) => (
  <span className="ball-scale">
    <div
      style={{
        margin: "-0.25em 0.5em",
        height: "16px",
        width: "16px",
        backgroundColor: color,
      }}
    />
  </span>
);
