export type SelectOption = {
  value: string;
  label: string;
};

export const displaySelectCell = (options: SelectOption[]) => ({
  cell: { value },
}: any) => (
  <div>
    {(options.find((x: SelectOption) => x.value === value) || {}).label}
  </div>
);
