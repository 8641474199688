import React from "react";

const SortArrow = ({ isSortedDesc, isSorted }) => {
  return (
    <span
      style={{ marginLeft: "1em", color: isSorted ? "#777777" : "lightGray" }}
    >
      {isSortedDesc ? "▼" : "▲"}
    </span>
  );
};

export default SortArrow;
