import { useEffect, useState } from "react";

import Axios from "axios";

import { FormButtonfilled, FormInputLabel } from "..";
import { optionsTypes, SelectInput } from "../forms/SelectInput";

interface RequestListTypes {
  onRun(invoiceId: string): void;
}

interface QuarterType {
  id: string;
  description: string;
}

export function BatchEligibility(props: RequestListTypes) {
  const { onRun } = props;

  const [selectedQuarter, setSelectedQuarter] = useState<optionsTypes[]>([
    {
      label: "",
      value: "",
    },
  ]);
  const [quarters, setQuarters] = useState<optionsTypes[]>([
    {
      label: "",
      value: "",
    },
  ]);
  const [quarterResponse, setQuarterResponse] = useState<QuarterType[]>([]);

  useEffect(() => {
    const getQuarters = async () => {
      const response: QuarterType[] = (await Axios.get(`/api/quarters`)).data;
      setQuarterResponse(response);
      const quarterOptions: optionsTypes[] = response.map((quarter) => {
        return {
          label: quarter.description,
          value: quarter.id,
        };
      });
      setQuarters(quarterOptions);
    };
    getQuarters();
  }, []);

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ width: "25em" }}>
          <FormInputLabel>
            Please select the quarter to generate invoices:
          </FormInputLabel>
          <SelectInput
            data-cy="selectInvoiceDropdown"
            options={quarters}
            searchable={false}
            onChange={(value) => {
              setSelectedQuarter(value);
            }}
            selected={selectedQuarter}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 1,
              marginTop: "1em",
            }}
          >
            <FormButtonfilled
              disabled={!selectedQuarter[0].value || !selectedQuarter[0].value}
              onClick={() => {
                onRun(selectedQuarter[0].value!);
              }}
            >
              Start Invoice Processing
            </FormButtonfilled>
          </div>
        </div>
      </div>
    </>
  );
}
