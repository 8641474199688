import React from "react";
import DatePicker from "react-date-picker";
import { Global, css } from "@emotion/react";
import COLORS from "../colors";
import { FormInputContainer, FormInputLabel } from ".";
import Select from "react-dropdown-select";

export interface optionsTypes {
  value: string | null;
  label: string;
}

interface SelectInputType {
  onChange(value: optionsTypes[]): void;
  children?: React.ReactNode;
  selected: optionsTypes[];
  options: optionsTypes[];
  "data-cy"?: string;
  searchable?: boolean;
}

export const SelectInput = (props: SelectInputType) => {
  const { onChange, selected, options, searchable, children } = props;
  return (
    <div data-cy={props["data-cy"]}>
      <FormInputContainer>
        {children && <FormInputLabel>{children}</FormInputLabel>}
        <Select
          searchable={searchable}
          placeholder=""
          options={options}
          onChange={onChange}
          values={selected}
        />
      </FormInputContainer>
    </div>
  );
};
