import React, { useEffect, useState } from "react";
import {
  FormHeader,
  Divider,
  InputFull,
  CheckBox,
  FormButtonOutfilled,
  FormButtonfilled,
} from "src/components/forms";

import styled from "@emotion/styled";
import { optionsTypes, SelectInput } from "../forms/SelectInput";
import { FormikProps } from "formik";
import { FormikInitValues } from "../HeroSubmissionForm";
import { InputRow } from "../forms/InputRow";
import { UseUserInfoTypes } from "src/hooks/useUserInfo";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { statusOptions } from "../AdminQueue/RequestList";
import { SelectOption } from "../StandardTable/displaySelectCell";
import { DatePickerInput } from "src/components/forms/DatePickerInput";
import axios from "axios";

const ButtonContainer = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
`;

const FieldContainer = styled.div`
  display: flex;
`;

export const OCCRRAAdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5em 0.5em 0.5em 0em;
  flex: 0.26;
`;

interface OCCRRAAdminSectionTypes {
  isOrg: boolean;
  userInfo: UseUserInfoTypes;
  viewOnly?: boolean;
}

interface InvoiceType {
  id: string;
  label: string;
  paymentDate: string | null;
}

const UNABLE_TO_VERIFY = "Unable to Verify";

export function OCCRRAAdminSection(
  props: FormikProps<FormikInitValues> & OCCRRAAdminSectionTypes
) {
  const { userInfo, isOrg } = props;
  const [paymentStatus, setPaymentStatus] = useState<optionsTypes[]>([
    {
      label: (
        statusOptions.find(
          (x: SelectOption) => x.value === userInfo.vendorStatus
        ) || {}
      ).label!,
      value: (
        statusOptions.find(
          (x: SelectOption) => x.value === userInfo.vendorStatus
        ) || {}
      ).value!,
    },
  ]);
  const [paymentMethod, setPaymentMethod] = useState<optionsTypes[]>([
    {
      label: userInfo.paymentMethod,
      value: userInfo.paymentMethod,
    },
  ]);
  const [selectedInvoice, setSelectedInvoice] = useState<optionsTypes[]>([
    {
      label: "",
      value: "",
    },
  ]);
  const [invoices, setInvoices] = useState<optionsTypes[]>([
    {
      label: "",
      value: "",
    },
  ]);
  const [invoiceResponse, setInvoiceResponse] = useState<InvoiceType[]>([]);
  const [defaultPaymentDate, setDefaultPaymentDate] = useState<string>("");
  const history = useHistory();
  useEffect(() => {
    const getInvoices = async () => {
      const response: InvoiceType[] = (
        await Axios.get(`/api/vendor/${userInfo.requestId}/invoices`)
      ).data;
      setInvoiceResponse(response);
      const invoiceOptions: optionsTypes[] = response.map((invoice) => {
        return {
          label: invoice.label,
          value: invoice.id,
        };
      });
      setInvoices(invoiceOptions);
    };
    getInvoices();
  }, []);
  const selectedInvoicePaymentMapper = (selectedInvoiceId: string) => {
    const invoice = invoiceResponse.filter((invoice: InvoiceType) => {
      return invoice.id === selectedInvoiceId;
    });
    if (invoice.length > 0 && invoice[0].paymentDate) {
      setDefaultPaymentDate(`${invoice[0].paymentDate} EST`);
    } else {
      setDefaultPaymentDate("");
    }
  };
  const onSubmitForm = async () => {
    try {
      await Axios.post(`/api/vendor/${userInfo.requestId}`, {
        payment_status: paymentStatus[0].value,
        payment_method: paymentMethod[0].value,
        invoice_id: selectedInvoice[0].value,
        invoice_payment_date: new Date(defaultPaymentDate).toLocaleDateString(
          "en-US"
        ),
      });
      alert("Data has updated successfully!");
      history.goBack();
    } catch (e) {
      alert("An Error has occurred updating data"!);
    }
  };
  return (
    <>
      <Divider />
      <FormHeader>OCCRRA Admin Section</FormHeader>
      <FieldContainer>
        <OCCRRAAdminContainer>
          <SelectInput
            data-cy="vendorStatusDropDown"
            options={[
              {
                label: "-",
                value: null,
              },
              {
                label: "Submitted",
                value: "submitted",
              },
              {
                label: "Processing",
                value: "processing",
              },
              {
                label: "Processed",
                value: "processed",
              },
              {
                label: "Unable to Verify",
                value: "unabletoverify",
              },
            ]}
            onChange={(value) => {
              setPaymentStatus(value);
            }}
            selected={paymentStatus}
          >
            Vendor Status
          </SelectInput>
        </OCCRRAAdminContainer>

        <OCCRRAAdminContainer>
          <SelectInput
            data-cy="paymentMethodDropDown"
            options={[
              {
                label: "-",
                value: null,
              },
              {
                label: "Electronic Funds Transfer",
                value: "Electronic Funds Transfer",
              },
              {
                label: "Check",
                value: "Check",
              },
            ]}
            onChange={(value) => {
              setPaymentMethod(value);
            }}
            selected={paymentMethod}
          >
            Payment Method
          </SelectInput>
        </OCCRRAAdminContainer>
        <OCCRRAAdminContainer />
        <OCCRRAAdminContainer>
          <SelectInput
            data-cy="selectInvoiceDropdown"
            options={invoices}
            searchable={false}
            onChange={(value) => {
              setSelectedInvoice(value);
              selectedInvoicePaymentMapper(value[0].value!);
            }}
            selected={selectedInvoice}
          >
            Select Invoice:
          </SelectInput>
        </OCCRRAAdminContainer>

        <OCCRRAAdminContainer>
          <DatePickerInput
            defaultDate={
              defaultPaymentDate ? new Date(defaultPaymentDate) : null
            }
            name="invoicePaymentDate"
            onChange={(date) => {
              if (date) {
                setDefaultPaymentDate(date.toString())
                } else {
                  setDefaultPaymentDate("")
                }
            }
            }
          >
            Specify Payment Date
          </DatePickerInput>
        </OCCRRAAdminContainer>
      </FieldContainer>
      <ButtonContainer>
        <FormButtonOutfilled
          onClick={() => {
            history.goBack();
          }}
        >
          Cancel{" "}
        </FormButtonOutfilled>
        <FormButtonOutfilled
          onClick={() => {
            if (isOrg) {
              history.push("/view" + window.location.search);
            } else {
              history.push("/view" + window.location.search);
            }
          }}
        >
          Render W9 View{" "}
        </FormButtonOutfilled>
        <FormButtonfilled
          disabled={!paymentStatus[0].value || !paymentMethod[0].value}
          data-cy="submitForm"
          onClick={onSubmitForm}
        >
          Submit Request{" "}
        </FormButtonfilled>
      </ButtonContainer>
    </>
  );
}
