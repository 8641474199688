import styled from "@emotion/styled";
import { useContext } from "react";
import { AuthContext } from "src/providers/AuthProvider";

const StyledHeader = styled.div`
  z-index: 1;
  background-color: #032b4b;
  position: sticky;
  top: 0;
  color: white;
  display: flex;
  align-content: center;
  h1 {
    font-size: 24px;
    margin-block-start: 0em;
    margin-block-end: 0;
    padding: 0.5em;
    margin: 0;
    font-family: Lato, sans-serif;
    z-index: 1;
    flex: auto;
  }

  button {
    margin: auto;
    visibility: visible;
    background-color: unset;
    color: white;
    position: relative;
    border: none;
    padding: 1em;
    cursor: pointer;
    margin-right: 1em;
  }
  button:hover {
    span {
      visibility: hidden;
    }
  }
  button:hover::after {
    background: white;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    color: black;
    padding: 1em;
    content: "Sign out?" !important;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 100px;
  height: auto;
  margin-right: 20px;
  vertical-align: middle;
`;

export function Header() {
  const auth: any = useContext(AuthContext);
  const { userAttributes = {} } = auth;
  const { email } = userAttributes;
  document.title = "Payment Portal";
  return (
    <StyledHeader>
      <h1>
        <Logo src="/images/registry-logo.png" alt="logo" />
        Payment Portal
      </h1>
      {email && (
        <button type="button" onClick={() => auth.logout()}>
          <span>{(auth.userAttributes || {}).email}</span>
        </button>
      )}
    </StyledHeader>
  );
}
