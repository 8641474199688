import styled from "@emotion/styled";
import { values } from "cypress/types/lodash";
import { FormikProps } from "formik";
import React, { useState } from "react";
import {
  InputFull,
  FormHeader,
  FormSubHeader,
  Divider,
  FormInputLabel,
  CheckBox,
} from "src/components/forms";
import { InputRow } from "../forms/InputRow";
import { FormikInitValues } from "../HeroSubmissionForm";

const RadioButton = styled.input`
  margin-bottom: 0.1em;
  margin-right: 0.7em;
  height: 1.5em;
  margin-left: 0;
`;

const NoBankAccountCheckContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const CheckBoxStyle = styled.div`
  margin-top: 1em;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
`;


interface BankAccountOwnerInformationSectionTypes {
  isDisabled?: boolean;
  onBankAvailableChange(value: boolean): void;
}

export function BankAccountOwnerInformationSection(
  props: FormikProps<FormikInitValues> & BankAccountOwnerInformationSectionTypes
) {
  const {
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    isDisabled,
    onBankAvailableChange,
    values,
  } = props;

  const radiobuttonSelected = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    setFieldValue("accountType", selectedValue, true);
    setFieldTouched("accountType", true, false);
  };

  const requestCheckClicked = (isChecked: boolean) => {
    onBankAvailableChange(isChecked);
    setFieldValue("requestCheck", isChecked, true);
    setFieldTouched("requestCheck", true, false);
  };

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const value = e.target.value.trim();
    setFieldValue(field, value, true);
    setFieldTouched(field, true, false);
  };

  const accountTypeError = !!(errors.accountType && touched.accountType);

  const routingError = !!(
    (errors.routingNumber && touched.routingNumber) ||
    (errors.routingNumberConfirm && touched.routingNumberConfirm)
  );
  const accountNumberError = !!(
    (errors.accountNumber && touched.accountNumber) ||
    (errors.accountNumberConfirm && touched.accountNumberConfirm)
  );

  return (
    <>
      <Divider />
      <div style={{display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center"}}>
      <FormHeader>Bank Account Owner Information</FormHeader>
      <FormSubHeader>
        It is the professional's responsibility to enter banking information correctly. 
        Professionals have one opportunity to successfully enter bank account information. 
        GIACT is being used to review the information entered by the professional to approve ACH requests.
      </FormSubHeader>
      </div>
      <br/>
      <InputRow
        rowText="Please Select Account Type:"
        rows={[
          <FormInputLabel
            data-cy="bankingInformationAccountTypeCheckingLabel"
            error={accountTypeError}
          >
            <RadioButton
              disabled={isDisabled}
              data-cy="bankingInformationAccountTypeCheckingRadio"
              onClick={radiobuttonSelected}
              type="radio"
              name="bankAccount"
              value="checking"
              checked={values.accountType === "checking"}
            />{" "}
            Checking Account
          </FormInputLabel>,
          <FormInputLabel
            data-cy="bankingInformationAccountTypeSavingsLabel"
            error={accountTypeError}
          >
            <RadioButton
              disabled={isDisabled}
              data-cy="bankingInformationAccountTypeSavingsRadio"
              onClick={radiobuttonSelected}
              type="radio"
              name="bankAccount"
              value="savings"
              checked={values.accountType === "savings"}
            />
            Savings Account
          </FormInputLabel>,
        ]}
        errorText={errors.accountType}
        isError={accountTypeError}
      />
      <InputRow
        rowText="Your name must match your bank statements."
        rows={[
          <InputFull
            value={values.firstName}
            disabled={true}
            data-cy="bankingInformationFirstName"
          >
            First Name
          </InputFull>,
          <InputFull
            value={values.lastName}
            disabled={true}
            data-cy="bankingInformationLastName"
          >
            Last Name
          </InputFull>,
        ]}
      />
      <InputRow
        errorLabelCypressLabel="bankingInformationRoutingErrorLabel"
        rowText="Routing Number: Please carefully enter the nine digit routing. Usually listed on the bottom of a personal check."
        rows={[
          <InputFull
            value={values.routingNumber}
            disabled={isDisabled}
            data-cy="bankingInformationRoutingNumber"
            error={!!(errors.routingNumber && touched.routingNumber)}
            onChange={(e) => {
              setFieldValue("routingNumber", e.target.value, false);
            }}
            onBlur={(e) => {
              onInputChange(e, "routingNumber");
            }}
          >
            Routing Number
          </InputFull>,
          <InputFull
            value={values.routingNumberConfirm}
            disabled={isDisabled}
            data-cy="bankingInformationRoutingNumberConfirmation"
            error={
              !!(errors.routingNumberConfirm && touched.routingNumberConfirm)
            }
            onChange={(e) => {
              setFieldValue("routingNumberConfirm", e.target.value, false);
            }}
            onBlur={(e) => {
              onInputChange(e, "routingNumberConfirm");
            }}
          >
            Routing Number (Please re-enter)
          </InputFull>,
        ]}
        errorText={
          (errors.routingNumber && touched.routingNumber
            ? errors.routingNumber
            : "") ||
          (errors.routingNumberConfirm && touched.routingNumberConfirm
            ? errors.routingNumberConfirm
            : "")
        }
        isError={routingError}
      />
      <InputRow
        errorLabelCypressLabel="bankingInformationAccountErrorLabel"
        rowText="Account Number: Please carefully enter your bank account number including leading zeros."
        rows={[
          <InputFull
            value={values.accountNumber}
            disabled={isDisabled}
            data-cy="bankingInformationAccountNumber"
            error={!!(errors.accountNumber && touched.accountNumber)}
            onChange={(e) => {
              setFieldValue("accountNumber", e.target.value, false);
            }}
            onBlur={(e) => {
              onInputChange(e, "accountNumber");
            }}
          >
            Account Number
          </InputFull>,
          <InputFull
            value={values.accountNumberConfirm}
            disabled={isDisabled}
            data-cy="bankingInformationAccountNumberConfirmation"
            error={
              !!(errors.accountNumberConfirm && touched.accountNumberConfirm)
            }
            onChange={(e) => {
              setFieldValue("accountNumberConfirm", e.target.value, false);
            }}
            onBlur={(e) => {
              onInputChange(e, "accountNumberConfirm");
            }}
          >
            Account Number (Please re-enter)
          </InputFull>,
        ]}
        errorText={
          (errors.accountNumber && touched.accountNumber
            ? errors.accountNumber
            : "") ||
          (errors.accountNumberConfirm && touched.accountNumberConfirm
            ? errors.accountNumberConfirm
            : "")
        }
        isError={accountNumberError}
      />
      <NoBankAccountCheckContainer>
        <CheckBoxStyle>
          <CheckBox onCheck={requestCheckClicked}
            subLabel="Please note: Hero Pay processing may take up to 8 weeks before you receive your payment."
          >
            No bank account available. (If selected the payment will be made via check)
          </CheckBox>
        </CheckBoxStyle>
      </NoBankAccountCheckContainer>
    </>
  );
}
