import React, { useEffect, useRef } from "react";

import { RippleButton } from "src/components/buttons";
import styled from "@emotion/styled";
import { Styles } from "./Styles";
const DULL_GRAY = "rgb(204, 204, 204)";

const StyledInput = styled.input`
  padding: 0.5em 0.7em;
  border-radius: 0.25em;
  max-height: 38px;
  font-size: 12px;
  border: thin solid ${DULL_GRAY};
  width: 100%;
`;

// const Styles = styled.div`
// `

const BUTTON_WIDTH = "60px";
export const PaginationControls = (props) => {
  const {
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    pageCount,
    pageSize,
    pageOptions,
    pageIndex,
    setPageSize,
  } = props;
  const pageIndexRef = useRef();

  useEffect(() => {
    pageIndexRef.current.value = pageIndex + 1;
  }, [pageIndex]);

  return (
    <Styles>
      <div className="pagination-controls">
        <RippleButton
          className="pagination--nav_button"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </RippleButton>{" "}
        <RippleButton
          className="pagination--nav_button"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </RippleButton>{" "}
        <div className="pagination--nav">
          <div className="pagination--page">
            <span>
              Page{" "}
              <StyledInput
                ref={pageIndexRef}
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />{" "}
              of {pageOptions.length}
            </span>
          </div>

          <div className="pagination--rows">
            <select
              style={{ background: "white", padding: "0.5em" }}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} Rows
                </option>
              ))}
            </select>
          </div>
        </div>
        <RippleButton
          className="pagination--nav_button"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </RippleButton>{" "}
        <RippleButton
          className="pagination--nav_button"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </RippleButton>{" "}
      </div>
    </Styles>
  );
};
