import { useEffect, useState } from "react";
import axios from "axios";
import { string } from "yup/lib/locale";

export interface UseUserInfoTypes {
  requestId: string;
  first_name: string;
  last_name: string;
  opin: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  county: string;
  zipCode: string;
  submissionInProgress: boolean;
  vendorStatus: string;
  paymentMethod: string;
  paymentDate: string;
  returnUrl: string;
  signatureDate: string | null;
  signature: string;
  tin?: string;
}

export interface UseCenterInfoTypes {
  w9EntityOtherInfo?: string | undefined;
  fiscalRepresentativeName?: string | undefined;
  fiscalRepresentativeEmail?: string | undefined;
  W9ExemptFATCACode?: string | undefined;
  w9ExemptPayeeCode?: string | undefined;
  nameAsOnTaxReturn?: string | undefined;
  tin?: string | undefined;
  w9EntityClassification?: string | undefined;
  w9EntityType?: string | undefined;
  admin_1_fname: string;
  admin_1_lname: string;
  admin_2_fname: string;
  admin_2_lname: string;
  admin_3_fname: string;
  admin_3_lname: string;
  center_type: string;
  child_care_food_program: string;
  county_contract: string;
  current_license_expiration_date: string;
  deleted: string;
  id: string;
  infant_capacity: string;
  infant_enrollment: string;
  infant_enrollment_pt: string;
  last_updated: string;
  license_number: string;
  max_capacity: string;
  naeyc_accredited: string;
  oin: string;
  mail_addr1: string;
  mail_addr2: string;
  mail_city: string;
  mail_name: string;
  mail_state: string;
  mail_zip: string;
  pre_school_capacity: string;
  pre_school_enrollment: string;
  pre_school_enrollment_pt: string;
  program_addr_1: string;
  program_addr_2: string;
  program_city: string;
  program_cnty: string;
  program_name: string;
  program_phone: string;
  program_state: string;
  program_status: string;
  program_zip: string;
  provider_email_address: string;
  provides_eli_services: string;
  school_age_capacity: string;
  school_age_enrollment: string;
  school_age_enrollment_pt: string;
  sutq_star_continously_since: string;
  sutq_star_rating: string;
  sutq_star_rating_end_date: string;
  sutq_star_rating_start_date: string;
  toddler_capacity: string;
  toddler_enrollment: string;
  toddler_enrollment_pt: string;
  under_30m: string;
  valid_on_list: string;
  vendor_id: string;
}

export default function useUserInfo(isOrg: boolean) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setisError] = useState(false);
  const [userInfo, setUserInfo] = useState<UseUserInfoTypes>({
    first_name: "",
    last_name: "",
    opin: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    county: "",
    zipCode: "",
    submissionInProgress: false,
    vendorStatus: "",
    paymentMethod: "",
    paymentDate: "",
    signatureDate: "",
    signature: "",
    requestId: "",
    returnUrl: "",
  });

  const [oprCenterInfo, setOprCenterInfo] = useState<UseCenterInfoTypes>({
    admin_1_fname: "",
    admin_1_lname: "",
    admin_2_fname: "",
    admin_2_lname: "",
    admin_3_fname: "",
    admin_3_lname: "",
    center_type: "",
    child_care_food_program: "",
    county_contract: "",
    current_license_expiration_date: "",
    deleted: "",
    id: "",
    infant_capacity: "",
    infant_enrollment: "",
    infant_enrollment_pt: "",
    last_updated: "",
    license_number: "",
    max_capacity: "",
    naeyc_accredited: "",
    oin: "",
    mail_addr1: "",
    mail_addr2: "",
    mail_city: "",
    mail_name: "",
    mail_state: "",
    mail_zip: "",
    pre_school_capacity: "",
    pre_school_enrollment: "",
    pre_school_enrollment_pt: "",
    program_addr_1: "",
    program_addr_2: "",
    program_city: "",
    program_cnty: "",
    program_name: "",
    program_phone: "",
    program_state: "",
    program_status: "",
    program_zip: "",
    provider_email_address: "",
    provides_eli_services: "",
    school_age_capacity: "",
    school_age_enrollment: "",
    school_age_enrollment_pt: "",
    sutq_star_continously_since: "",
    sutq_star_rating: "",
    sutq_star_rating_end_date: "",
    sutq_star_rating_start_date: "",
    toddler_capacity: "",
    toddler_enrollment: "",
    toddler_enrollment_pt: "",
    under_30m: "",
    valid_on_list: "",
    vendor_id: "",
  });

  async function getUserInfo() {
    try {
      setisError(false);
      console.log(`isOrg = ${isOrg}`);
      const userInput = await axios.get(`/api/submission/userInfo`, {
        withCredentials: true,
      });

      if (isOrg) {
        setOprCenterInfo(userInput.data.oprCenterInfo);
        setUserInfo({
          ...userInput.data.oprUserInfo.user,
          returnUrl: userInput.data.returnUrl,
        });
      } else {
        setUserInfo(userInput.data.oprUserInfo.user);
      }

      setIsLoading(false);
    } catch (e: any) {
      const newUserInfo = { ...userInfo };
      if (
        e &&
        e.response &&
        e.response.data &&
        e.response.data.status === 400
      ) {
        newUserInfo.submissionInProgress = true;
      }
      setUserInfo(newUserInfo);
      setisError(true);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  return [isLoading, isError, userInfo, oprCenterInfo];
}
