export default {
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  ENTER_PIN: "/enter-pin",
  ADMIN: "/admin-queue",
  HERO_SUMMARY: "/create_request",
  CREATE_HERO_SUBMISSION: "/create_request/submission",
  EDIT_HERO_SUBMISSION: "/edit_request/:id",
  ADMIN_HERO_SUBMISSION: "/request/:id",
  W9_VIEW: "/view",
};
