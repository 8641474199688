import React from "react";
import styled from "@emotion/styled";
import Loader from "./Loader";

const StyledLoadingContainer = styled.div`
  width: 100%;
  height: ${({ height }) => height || "100%"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid rgb(36, 98, 142);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(36, 98, 142) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .ball-scale div {
    height: 20px;
    width: 20px;

    -webkit-animation: ball-scale 1s 0s ease-in-out infinite;
    animation: ball-scale 1s 0s ease-in-out infinite;
  }
  .balls-scale {
    position: relative;
  }
  .nested {
    position: absolute;
  }
  .ball-pulse-sync > div,
  .ball-pulse > div,
  .ball-scale-random > div,
  .ball-scale > div {
    background-color: #fff;
    border-radius: 100%;
    margin: 2px;
    display: inline-block;
  }
  @keyframes ball-scale {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
`;

export function LoaderContainer(props) {
  const { height = "100%", message } = props;

  return (
    <StyledLoadingContainer data-cy={props["data-cy"]} height={height}>
      <Loader />
      <div>{message}</div>
    </StyledLoadingContainer>
  );
}
