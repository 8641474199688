import styled from "@emotion/styled";

export const Styles = styled.div`
  table {
    flex: auto;
    width: 100%;
    border-spacing: 0;
    border: 1px solid lightgray;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    thead {
      font-weight: bold;
      border-bottom: 2px solid lightgray;
    }

    th,
    td {
      margin: 0;
      border-bottom: 1px solid lightgray;
      border-right: 1px solid lightgray;

      :last-child {
        border-right: 0;
      }
    }
    th {
      line-height: normal;
      position: relative;
      padding: 0.5em;
      border-right: 1px solid lightgray;
      font-weight: 400;
      transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      box-shadow: inset 0 0 0 0 transparent;
      background-color: #f7f7f7;
      height: 3.5rem;
    }
    th[title*="SortBy"]:hover {
      cursor: pointer;
      background-color: #efefef;
    }
  }

  .pagination-controls {
    height: 3em;
    display: flex;
    background: #f7f7f7;
    border: 1px solid lightgray;
    button {
      margin: 0.5em;
      flex: nogrow;
      background: #efefef;
      border-radius: 0.2em;
    }
    input {
      text-align: center;
    }
    select {
      border: 1px solid lightgray;
    }
    button[disabled] {
      background: #fefefe;
      color: lightgray;
    }
  }
  .pagination--nav {
    flex: auto;
    font-weight: normal;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    display: flex;
  }
  .pagination--rows,
  .pagination--page {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .no-records {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4em;
  }
  .pagination--nav_button {
    width: 50px;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;
