import styled from "@emotion/styled";
import { FormikProps } from "formik";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import "tippy.js/dist/tippy.css";

import { FormikInitValues } from "src/components";

import {
  FormHeader,
  InputFull,
  FormContainer,
  Divider,
  FormInputLabel,
  CheckBox,
  ErrorLabel,
} from "src/components/forms";
import { InputRow } from "src/components/forms/InputRow";
import {
  LabelContainer,
  SSNCollectionType,
  SSNContainer,
  SSNSection,
} from "../PersonalInformationSection";
import { UseUserInfoTypes } from "src/hooks/useUserInfo";

const TextContainer = styled.span`
  font-size: 0.7em;
  margin: 1em 1em 2em;
`;

const RadioButton = styled.input`
  margin-bottom: 0.1em;
  margin-right: 0.7em;
  height: 1.5em;
  margin-left: 0;
`;

const RadioButtonTitle = styled.label`
  align-items: center;
  display: flex;
  font-size: 0.8em;
  margin: 1em 0em 1em 1em;
`;

interface OrgEntitySectionTypes {
  weight?: string;
  setSSNOverrideFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setOtherOrgSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setllcSelected: React.Dispatch<React.SetStateAction<boolean>>;
  isAdminView: boolean;
  isW9: boolean;
  userInfo: UseUserInfoTypes;
}

export function OrgEntitySection(
  props: FormikProps<FormikInitValues> & OrgEntitySectionTypes
) {
  const {
    errors,
    setFieldValue,
    setFieldTouched,
    touched,
    values,
    setSSNOverrideFlag,
    setOtherOrgSelected,
    isAdminView,
    isW9,
    setllcSelected,
    userInfo,
  } = props;
  const onRadioSelect = (e: any) => {
    console.log("eeee", e.target.value);
    switch (e.target.value) {
      case "other":
        setOtherOrgSelected(true);
        setllcSelected(false);
        break;
      case "llc":
        setllcSelected(true);
        setOtherOrgSelected(false);
        break;
      default:
        setOtherOrgSelected(false);
        setllcSelected(false);
    }
    setFieldValue("orgSelection", e.target.value, true);
    if (values.SSNOverride) {
      handleCheckbox(false);
    }
  };

  const changeSSNField = (
    field: keyof SSNCollectionType,
    value: string,
    MAX_LENGTH: string
  ) => {
    const focusOrder = [
      "SSN1",
      "SSN2",
      "SSN3",
      "SSN1Confirm",
      "SSN2Confirm",
      "SSN3Confirm",
    ];
    setFieldValue(field, value, true);
    if (value.length.toString() === MAX_LENGTH) {
      const nextField = focusOrder.indexOf(field);
      if (focusOrder[nextField + 1]) {
        document.getElementById(focusOrder[nextField + 1])?.focus();
      } else {
        document.getElementById(focusOrder[nextField])?.blur();
      }
    }
  };

  const handleCheckbox = (value: boolean) => {
    setFieldValue("EIN", "");
    setFieldTouched("EIN", false);
    setFieldValue("EINVerify", "");
    setFieldTouched("EINVerify", false);
    setFieldValue("EINName", "");
    setFieldTouched("EINName", false);
    setFieldValue("SSN1", "");
    setFieldTouched("SSN1", false);
    setFieldValue("SSN2", "");
    setFieldTouched("SSN2", false);
    setFieldValue("SSN3", "");
    setFieldTouched("SSN3", false);
    setFieldValue("SSN1Confirm", "");
    setFieldTouched("SSN1Confirm", false);
    setFieldValue("SSN2Confirm", "");
    setFieldTouched("SSN2Confirm", false);
    setFieldValue("SSN3Confirm", "");
    setFieldTouched("SSN3Confirm", false);
    setFieldValue("SSNName", "");
    setFieldTouched("SSNName", false);
    setFieldValue("SSNOverride", value, true);
    setSSNOverrideFlag(value);
    if (value && userInfo) {
      setFieldValue("SSNName", userInfo.first_name + " " + userInfo.last_name);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          right: -15,
          top: 30,
          width: 240,
          height: 400,
        }}
      >
        <div style={{ marginBottom: 10 }}>Exemptions</div>

        <InputFull
          value={values.ExemptPayee}
          disabled={isAdminView && !isW9}
          data-cy="failureCounterText"
          onChange={(e) => {
            setFieldValue("ExemptPayee", e.target.value);
          }}
        >
          Exempt Payee code
        </InputFull>
        <InputFull
          value={values.ExemptFATCA}
          disabled={isAdminView && !isW9}
          data-cy="failureCounterText"
          onChange={(e) => {
            setFieldValue("ExemptFATCA", e.target.value);
          }}
        >
          Exemption from FATCA reporting code
        </InputFull>
      </div>
      <Divider />
      <FormHeader>Organization Information</FormHeader>

      <RadioButtonTitle>
        Check appropriate box for federal tax classification. Check only one of
        the following seven boxes.
      </RadioButtonTitle>
      <FormContainer>
        <InputRow
          rows={[
            <FormInputLabel
              weight={"400"}
              data-cy="bankingInformationAccountTypeCheckingLabel"
              error={!!errors.orgSelection}
            >
              <RadioButton
                onChange={onRadioSelect}
                disabled={isAdminView && !isW9}
                data-cy="bankingInformationAccountTypeCheckingRadio"
                type="radio"
                name="entityType"
                value="individual"
                checked={values.orgSelection === "individual"}
              />
              Individual/sole proprietor or single-member LLC
            </FormInputLabel>,
          ]}
          isError={!!errors.orgSelection}
        />
      </FormContainer>
      <FormContainer>
        <InputRow
          rows={[
            <FormInputLabel
              weight={"400"}
              data-cy="bankingInformationAccountTypeCheckingLabel"
              error={!!errors.orgSelection}
            >
              <RadioButton
                disabled={isAdminView && !isW9}
                onChange={onRadioSelect}
                data-cy="bankingInformationAccountTypeCheckingRadio"
                type="radio"
                name="entityType"
                value="ccorp"
                checked={values.orgSelection === "ccorp"}
              />
              C Corporation
            </FormInputLabel>,
          ]}
          isError={!!errors.orgSelection}
        />
      </FormContainer>
      <FormContainer>
        <InputRow
          rows={[
            <FormInputLabel
              weight={"400"}
              data-cy="bankingInformationAccountTypeCheckingLabel"
              error={!!errors.orgSelection}
            >
              <RadioButton
                disabled={isAdminView && !isW9}
                onChange={onRadioSelect}
                data-cy="bankingInformationAccountTypeCheckingRadio"
                type="radio"
                name="entityType"
                value="scorp"
                checked={values.orgSelection === "scorp"}
              />
              S Corporation
            </FormInputLabel>,
          ]}
          isError={!!errors.orgSelection}
        />
      </FormContainer>
      <FormContainer>
        <InputRow
          rows={[
            <FormInputLabel
              weight={"400"}
              data-cy="bankingInformationAccountTypeCheckingLabel"
              error={!!errors.orgSelection}
            >
              <RadioButton
                disabled={isAdminView && !isW9}
                onChange={onRadioSelect}
                data-cy="bankingInformationAccountTypeCheckingRadio"
                type="radio"
                name="entityType"
                value="partner"
                checked={values.orgSelection === "partner"}
              />
              Partnership
            </FormInputLabel>,
          ]}
          isError={!!errors.orgSelection}
        />
      </FormContainer>
      <FormContainer>
        <InputRow
          rows={[
            <FormInputLabel
              weight={"400"}
              data-cy="bankingInformationAccountTypeCheckingLabel"
              error={!!errors.orgSelection}
            >
              <RadioButton
                disabled={isAdminView && !isW9}
                onChange={onRadioSelect}
                data-cy="bankingInformationAccountTypeCheckingRadio"
                onClick={() => {}}
                type="radio"
                name="entityType"
                value="trust"
                checked={values.orgSelection === "trust"}
              />
              Trust/estate
            </FormInputLabel>,
          ]}
          isError={!!errors.orgSelection}
        />
      </FormContainer>
      <FormContainer>
        <InputRow
          rows={[
            <FormInputLabel
              weight={"400"}
              data-cy="bankingInformationAccountTypeCheckingLabel"
              error={!!errors.orgSelection}
            >
              <RadioButton
                disabled={isAdminView && !isW9}
                onChange={onRadioSelect}
                data-cy="bankingInformationAccountTypeCheckingRadio"
                onClick={() => {}}
                type="radio"
                name="entityType"
                value="llc"
                checked={values.orgSelection === "llc"}
              />
              Limited liability company. Enter the tax classification
              (C&nbsp;=&nbsp;C corporation, S&nbsp;=&nbsp;S corporation,
              P&nbsp;=&nbsp;Partnership)
              <InputFull
                value={values.llcType}
                small
                onChange={(e) => {
                  setFieldValue("llcType", e.target.value.toLowerCase(), true);
                }}
                onBlur={(e) => {
                  setFieldTouched("llcType", true);
                }}
                disabled={
                  (isAdminView && !isW9) || values.orgSelection !== "llc"
                }
                error={
                  values.orgSelection === "llc" &&
                  touched.llcType &&
                  !!errors.llcType
                }
              />
              <ErrorLabel
                style={{
                  width: "38em",
                  fontSize: "1.4em",
                }}
              >
                {values.orgSelection === "llc" &&
                  touched.llcType &&
                  !!errors.llcType &&
                  errors.llcType}
                &nbsp;
              </ErrorLabel>
            </FormInputLabel>,
          ]}
          isError={!!errors.orgSelection || !!errors.llcType}
        />
      </FormContainer>
      <FormContainer>
        <TextContainer>
          Note: Check the appropriate box in the line above for the tax
          classification of the single-member owner. Do not check LLC if the LLC
          is classified as a single-member LLC that is disregarded from the
          owner unless the owner of the LLC is another LLC that is not
          disregarded from the owner for U.S. federal tax purposes. Otherwise, a
          single-member LLC that is disregarded from the owner should check the
          appropriate box for the tax classification of its owner.
        </TextContainer>
      </FormContainer>
      <FormContainer>
        <InputRow
          rows={[
            <FormInputLabel
              data-cy="bankingInformationAccountTypeCheckingLabel"
              error={!!errors.orgSelection}
              weight={"400"}
            >
              <RadioButton
                onChange={onRadioSelect}
                disabled={isAdminView && !isW9}
                data-cy="bankingInformationAccountTypeCheckingRadio"
                type="radio"
                name="entityType"
                value="other"
                checked={values.orgSelection === "other"}
              />
              Other &nbsp;
              <InputFull
                value={values.orgOther}
                onChange={(e) => {
                  setFieldValue("orgOther", e.target.value, true);
                }}
                disabled={
                  (isAdminView && !isW9) || values.orgSelection !== "other"
                }
                error={values.orgSelection === "other" && !!errors.orgOther}
              />
            </FormInputLabel>,
          ]}
          isError={!!errors.orgSelection}
        />
      </FormContainer>
      <br />
      <FormContainer
        style={{
          justifyContent: "space-between",
        }}
      >
        <SSNSection>
          <InputFull
            disabled={(isAdminView && !isW9) || values.SSNOverride}
            error={!values.SSNOverride && !!errors.EIN && touched.EIN}
            value={values.EIN}
            onChange={(e) => {
              setFieldValue("EIN", e.target.value, true);
            }}
            onBlur={() => {
              setFieldTouched("EIN", true);
            }}
            maxLength={10}
          >
            Employer Identification Number
          </InputFull>
        </SSNSection>
        <SSNSection>
          <InputFull
            disabled={(isAdminView && !isW9) || values.SSNOverride}
            error={
              !values.SSNOverride && !!errors.EINVerify && touched.EINVerify
            }
            value={values.EINVerify}
            onChange={(e) => {
              setFieldValue("EINVerify", e.target.value, true);
            }}
            onBlur={(e) => {
              setFieldTouched("EINVerify", true);
            }}
            maxLength={10}
          >
            Please re-enter Employer Identification Number
          </InputFull>
          {!values.SSNOverride &&
            ((touched.EIN && errors.EIN && touched.EIN) ||
              (touched.EINVerify && errors.EINVerify && touched.EINVerify) ||
              (touched.EINName && errors.EINName && touched.EINName)) && (
              <ErrorLabel>
                {errors.EIN || errors.EINVerify || errors.EINName}
              </ErrorLabel>
            )}
        </SSNSection>
        <SSNSection>
          <InputFull
            disabled={(isAdminView && !isW9) || values.SSNOverride}
            error={!values.SSNOverride && !!errors.EINName && touched.EINName}
            value={values.EINName}
            onChange={(e) => {
              setFieldValue("EINName", e.target.value, true);
            }}
            onBlur={() => {
              setFieldTouched("EINName", true);
            }}
          >
            Name as shown on income tax return.
          </InputFull>
        </SSNSection>
      </FormContainer>
      {!isAdminView && (
        <>
          <Tippy
            content={
              values.orgSelection !== "individual"
                ? "This checkbox is disabled because the tax selection is not 'Individual/sole proprietor or single-member LLC'."
                : ""
            }
            theme="light"
            placement="top-start"
            arrow={true}
          >
            <FormContainer>
              <CheckBox
                disabled={values.orgSelection !== "individual"}
                onCheck={handleCheckbox}
                checked={values.SSNOverride}
              >
                I do not have an Employer Identification Number and will use my
                SSN.
              </CheckBox>
            </FormContainer>
          </Tippy>
          <br />
          <FormContainer justifyContent="flex-end">
            <div style={{ flex: 0.4 }}>
              <SSNSection>
                <LabelContainer>
                  <FormInputLabel
                    data-cy="SSNLabel"
                    error={
                      !!values.SSNOverride &&
                      !!(
                        (touched.SSN1 && errors.SSN1) ||
                        (touched.SSN2 && errors.SSN2) ||
                        (touched.SSN3 && errors.SSN3) ||
                        (touched.SSN1Confirm && errors.SSN1Confirm) ||
                        (touched.SSN2Confirm && errors.SSN2Confirm) ||
                        (touched.SSN3Confirm && errors.SSN3Confirm)
                      )
                    }
                  >
                    Social Security Number
                  </FormInputLabel>
                </LabelContainer>
                <SSNContainer>
                  <InputFull
                    disabled={!values.SSNOverride}
                    value={values.SSN1}
                    data-cy="SSN1"
                    onChange={(e) => {
                      changeSSNField("SSN1", e.target.value, "3");
                    }}
                    id="SSN1"
                    error={values.SSNOverride && !!errors.SSN1 && touched.SSN1}
                    onBlur={(e) => {
                      setFieldTouched("SSN1", true);
                    }}
                    small
                    maxLength={3}
                  />
                  <InputFull
                    disabled={!values.SSNOverride}
                    value={values.SSN2}
                    data-cy="SSN2"
                    onChange={(e) => {
                      changeSSNField("SSN2", e.target.value, "2");
                    }}
                    id="SSN2"
                    error={values.SSNOverride && !!errors.SSN2 && touched.SSN2}
                    onBlur={(e) => {
                      setFieldTouched("SSN2", true);
                    }}
                    small
                    maxLength={2}
                  />
                  <InputFull
                    disabled={!values.SSNOverride}
                    value={values.SSN3}
                    data-cy="SSN3"
                    onChange={(e) => {
                      changeSSNField("SSN3", e.target.value, "4");
                    }}
                    id="SSN3"
                    error={values.SSNOverride && !!errors.SSN3 && touched.SSN3}
                    onBlur={(e) => {
                      setFieldTouched("SSN3", true);
                    }}
                    small
                    maxLength={4}
                  />
                </SSNContainer>
              </SSNSection>
            </div>
            <div style={{ flex: 0.4 }}>
              <SSNSection>
                <LabelContainer>
                  <FormInputLabel
                    data-cy="SSNConfirmLabel"
                    error={
                      !!values.SSNOverride &&
                      !!(
                        (touched.SSN1 && errors.SSN1) ||
                        (touched.SSN2 && errors.SSN2) ||
                        (touched.SSN3 && errors.SSN3) ||
                        (touched.SSN1Confirm && errors.SSN1Confirm) ||
                        (touched.SSN2Confirm && errors.SSN2Confirm) ||
                        (touched.SSN3Confirm && errors.SSN3Confirm)
                      )
                    }
                  >
                    Social Security Number (Please re-enter)
                  </FormInputLabel>
                </LabelContainer>

                <SSNContainer>
                  <InputFull
                    disabled={!values.SSNOverride}
                    value={values.SSN1Confirm}
                    data-cy="SSN1Confirm"
                    id="SSN1Confirm"
                    onChange={(e) => {
                      changeSSNField("SSN1Confirm", e.target.value, "3");
                    }}
                    error={
                      values.SSNOverride &&
                      !!errors.SSN1Confirm &&
                      touched.SSN1Confirm
                    }
                    onBlur={(e) => {
                      setFieldTouched("SSN1Confirm", true);
                    }}
                    small
                    maxLength={3}
                  />
                  <InputFull
                    disabled={!values.SSNOverride}
                    value={values.SSN2Confirm}
                    data-cy="SSN2Confirm"
                    id="SSN2Confirm"
                    onChange={(e) => {
                      changeSSNField("SSN2Confirm", e.target.value, "2");
                    }}
                    error={
                      values.SSNOverride &&
                      !!errors.SSN2Confirm &&
                      touched.SSN2Confirm
                    }
                    onBlur={(e) => {
                      setFieldTouched("SSN2Confirm", true);
                    }}
                    small
                    maxLength={2}
                  />
                  <InputFull
                    disabled={!values.SSNOverride}
                    value={values.SSN3Confirm}
                    data-cy="SSN3Confirm"
                    id="SSN3Confirm"
                    onChange={(e) => {
                      changeSSNField("SSN3Confirm", e.target.value, "4");
                    }}
                    error={
                      values.SSNOverride &&
                      !!errors.SSN3Confirm &&
                      touched.SSN3Confirm
                    }
                    onBlur={(e) => {
                      setFieldTouched("SSN3Confirm", true);
                    }}
                    small
                    maxLength={4}
                  />
                </SSNContainer>
                {values.SSNOverride &&
                  !!(
                    (touched.SSN1 && errors.SSN1) ||
                    (touched.SSN2 && errors.SSN2) ||
                    (touched.SSN3 && errors.SSN3) ||
                    (touched.SSN1Confirm && errors.SSN1Confirm) ||
                    (touched.SSN2Confirm && errors.SSN2Confirm) ||
                    (touched.SSN3Confirm && errors.SSN3Confirm)
                  ) && (
                    <ErrorLabel>
                      {errors.SSN1 ||
                        errors.SSN2 ||
                        errors.SSN3 ||
                        errors.SSN1Confirm ||
                        errors.SSN2Confirm ||
                        errors.SSN3Confirm}
                    </ErrorLabel>
                  )}
              </SSNSection>
            </div>
            <div style={{ flex: 0.4 }}>
              <InputFull
                onChange={(e) => {
                  setFieldValue("SSNName", e.target.value, true);
                }}
                onBlur={() => {
                  setFieldTouched("SSNName", true);
                }}
                value={values.SSNName}
                error={
                  values.SSNOverride && touched.SSNName && !!errors.SSNName
                }
                disabled={true}
              >
                Name as shown on your income tax return.
              </InputFull>
            </div>
          </FormContainer>
        </>
      )}
      <br />
      <FormInputLabel>
        Internal Revenue Service (IRS){" "}
        <a
          href="https://www.irs.gov/instructions/iw9"
          target="_blank"
          rel="noreferrer noopener"
        >
          (https://www.irs.gov/instructions/iw9)
        </a>
      </FormInputLabel>
    </div>
  );
}
