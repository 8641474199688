// import Yubikey from "./Yubikey.gif";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { SummarySection } from "./formSections/SummarySection";
import { OrgSummarySection } from "./formSections/orgForms/OrgSummarySection";
import COLORS from "./colors";

import {
  FormButtonOutfilled,
  FormTitle,
  InformationalHeader,
} from "src/components/forms";
import { FaUserLock } from "react-icons/fa";
import { PersonalInformationSection } from "src/components/formSections/PersonalInformationSection";
import { BankAccountOwnerInformationSection } from "src/components/formSections/BankAccountOwnerInformationSection";
import { BankAccountOwnerInformationSection as BankAccountOwnerInformationSectionOrg } from "src/components/formSections/orgForms/BankAccountOwnerInformationSection";
import { SignatureSection } from "src/components/formSections/SignatureSection";
import { ReasonCodeSection } from "./formSections/ReasonCodeSection";
import { OCCRRAAdminSection } from "./formSections/OCCRRAAdminSection";
import { OrgEntitySection } from "./formSections/orgForms/OrgEntitySection";
import { Formik } from "formik";
import { ConfirmationModal } from "./ConfirmationModal";
import { UseCenterInfoTypes, UseUserInfoTypes } from "src/hooks/useUserInfo";
import axios from "axios";
import { createValidationSchema } from "./validationSchemas/submissionFormSchema";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const ButtonContainer = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  padding: 2em 1em;
  justify-content: center;
`;

const HeroSubmissionContainer = styled.div`
  position: relative;
  display: flex;
  flex: 0.7;
  padding: 2em 1em;
  flex-direction: column;
  border: 1px solid ${COLORS.LIGHT_GRAY};
  border-radius: 0.3em;
`;

const Logo = styled.img`
  position: absolute;
  right: 0;
  top: 5px;
`;

interface showSignatureSectionTypes {
  hideAllButtons: boolean;
  disableFields: boolean;
}

interface HeroSubmissionFormTypes {
  isAdmin: boolean;
  userInfo: UseUserInfoTypes;
  edit: boolean;
  viewOnly: boolean;
  showPersonalSection: boolean;
  showBankAccountSection: boolean;
  showSignatureSection: showSignatureSectionTypes;
  showAdminSection: boolean;
  showSummarySection: boolean;
  showReasonCodeSection: boolean;
  submissionMode: boolean;
  showGoBackButton: boolean;
  isOrg?: boolean;
  isAdminView: boolean;
  isW9: boolean;
  oprCenterInfo: UseCenterInfoTypes;
}

export interface FormikInitValues {
  SSN1: string;
  SSN1Confirm: string;
  SSN2: string;
  SSN2Confirm: string;
  SSN3: string;
  SSN3Confirm: string;
  accountType: string;
  firstName: string;
  lastName: string;
  opin: string;
  routingNumber: string;
  routingNumberConfirm: string;
  accountNumber: string;
  accountNumberConfirm: string;
  requestCheck: boolean;
  adminPaymentDate: string;
  signature: string;
  signatureDate: string;
  orgSelection?: string;
  orgOther?: string;
  tin?: string;
  EIN?: string;
  EINVerify?: string;
  EINName?: string;
  SSNOverride?: boolean;
  SSNName?: string;
  ExemptPayee?: string;
  ExemptFATCA?: string;
  corpAccount?: string;
  noBankAccountAvailable?: string;
  payeeName?: string;
  repName?: string;
  repEmail?: string;
  llcType?: string;
}

export function HeroSubmissionForm(props: HeroSubmissionFormTypes) {
  const {
    userInfo,
    edit,
    showAdminSection,
    showSignatureSection,
    showBankAccountSection,
    showPersonalSection,
    showSummarySection,
    showReasonCodeSection,
    submissionMode,
    showGoBackButton,
    isOrg,
    isAdminView,
    isW9,
    oprCenterInfo,
  } = props;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationDate, setConfirmationDate] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [
    confirmationReferenceNumber,
    setConfirmationReferenceNumber,
  ] = useState("");
  const [confirmationTime, setConfirmationTime] = useState("");
  const [revertedToCheck, setRevertedToCheck] = useState(true);
  const [checkRequested, setCheckRequested] = useState("");
  const [confirmationRequestId, setConfirmationRequestId] = useState("");
  const [reasonCode, setReasonCode] = useState({
    personalInfo: false,
    bankingInfo: false,
  });

  const [bankAvailable, setBankAvailable] = useState(false);
  const [SSNOverrideFlag, setSSNOverrideFlag] = useState(false);
  const [isCorp, setIsCorp] = useState(false);
  const [hasBanking, setHasBanking] = useState(false);
  const [otherOrgSelected, setOtherOrgSelected] = useState(false);
  const [llcSelected, setllcSelected] = useState(false);
  const history = useHistory();

  const onOkModal = () => {
    window.location.href = userInfo.returnUrl;
  };

  const openModal = () => {
    setShowConfirmationModal(true);
  };

  let initialValues: FormikInitValues = {
    SSN1: "",
    SSN1Confirm: "",
    SSN2: "",
    SSN2Confirm: "",
    SSN3: "",
    SSN3Confirm: "",
    accountType: "",
    firstName: isOrg ? "" : userInfo.first_name,
    lastName: isOrg ? "" : userInfo.last_name,
    opin: userInfo.opin,
    tin: isOrg ? "" : userInfo.tin,
    routingNumber: "",
    routingNumberConfirm: "",
    accountNumber: "",
    accountNumberConfirm: "",
    requestCheck: false,
    adminPaymentDate: "",
    signature: userInfo.signature ? userInfo.signature : "",
    signatureDate: userInfo.signatureDate
      ? userInfo.signatureDate
      : new Date().toISOString(),
  };

  let validationSchema = () => {
    if (isOrg) {
      return createValidationSchema(
        userInfo.last_name,
        reasonCode,
        false,
        showReasonCodeSection,
        showBankAccountSection,
        showAdminSection,
        bankAvailable,
        true,
        SSNOverrideFlag,
        isCorp,
        hasBanking,
        true,
        otherOrgSelected,
        isAdminView,
        isW9,
        llcSelected
      );
    } else {
      return createValidationSchema(
        userInfo.last_name,
        reasonCode,
        showPersonalSection,
        showReasonCodeSection,
        showBankAccountSection,
        showAdminSection,
        bankAvailable,
        false,
        SSNOverrideFlag,
        isCorp,
        hasBanking,
        false,
        false,
        isAdminView,
        isW9,
        false
      );
    }
  };
  if (isOrg) {
    initialValues = {
      ...initialValues,
      orgSelection: oprCenterInfo.w9EntityType || "",
      orgOther: oprCenterInfo.w9EntityOtherInfo || "",
      EIN: oprCenterInfo.tin || "",
      EINVerify: oprCenterInfo.tin || "",
      EINName: oprCenterInfo.nameAsOnTaxReturn || "",
      SSNOverride: false,
      SSNName: "",
      ExemptPayee: oprCenterInfo.w9ExemptPayeeCode || "",
      ExemptFATCA: oprCenterInfo.W9ExemptFATCACode || "",
      payeeName: oprCenterInfo.nameAsOnTaxReturn || "",
      repEmail: oprCenterInfo.fiscalRepresentativeEmail || "",
      repName: oprCenterInfo.fiscalRepresentativeName || "",
      llcType: oprCenterInfo.w9EntityClassification || "",
    };
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (values: FormikInitValues) => {
    try {
      const response = await axios.post(`/api/submission/vendor`, {
        first_name: values.firstName,
        last_name: values.lastName,
        opin: values.opin,
        social: `${values.SSN1}${values.SSN2}${values.SSN3}`,
        account_type: values.accountType,
        routing_number: values.routingNumber,
        account_number: values.accountNumber,
        request_check: values.requestCheck ? 1 : 0, //
        signature: values.signature,
        signature_date: values.signatureDate,
        payee: values.EINName || values.SSNName, //
        tinType: values.EIN ? "ein" : "ssn", //
        fiscalRepresentativeEmail: values.repEmail, //
        fiscalRepresentativeName: values.repName, //
        w9EntityType: values.orgSelection, //
        w9EntityClassification: values.llcType, //
        w9EntityOtherInfo: values.orgOther, //
        nameAsOnTaxReturn: values.SSNName || values.EINName, //
        tin: values.EIN
          ? values.EIN.replace("-", "")
          : `${values.SSN1}${values.SSN2}${values.SSN3}`, //
        W9ExemptFATCACode: values.ExemptFATCA, //
        w9ExemptPayeeCode: values.ExemptPayee, //
      });
      const data = response.data;
      setConfirmationRequestId(data.requestId);
      setConfirmationDate(data.confirmationDate);
      setConfirmationTime(data.confirmationTime);
      setConfirmationMessage(data.message);
      setConfirmationReferenceNumber(data.referenceNumber);
      setRevertedToCheck(data.revertedToCheck);
      setCheckRequested(data.checkRequested);
      openModal();
    } catch (e: any) {
      let msg = "Please try again later.";
      if (e?.response?.data?.messages?.error) {
        msg = e.response.data.messages.error;
      }
      Swal.fire({
        title: "<strong>Error</strong>",
        icon: "error",
        html: "An Error occured submitting the form.<br/>" + msg,
      });
    }
  };
  return (
    <>
      <ConfirmationModal
        isOrg={!!isOrg}
        onOk={onOkModal}
        onClose={onOkModal}
        isOpen={showConfirmationModal}
        date={confirmationDate}
        time={confirmationTime}
        requestID={confirmationRequestId}
        checkRequested={checkRequested}
        revertedToCheck={revertedToCheck}
        confirmationMessage={confirmationMessage}
      />
      <Body>
        <HeroSubmissionContainer>
          <Logo
            height={100}
            src={require("../assets/icons/security-ssl.svg").default}
          />
          <div>
            <FormTitle>
              Payment Portal {!isOrg && " - HERO Submission Form"}
            </FormTitle>
          </div>
          <div>
            <InformationalHeader>
              {isOrg
                ? "You have entered OCCRRA's secure Payment Portal"
                : "You have entered a secure Payment Portal used for processing Hero Pay."}
              &nbsp; &nbsp;
              <FaUserLock size="3em" />{" "}
            </InformationalHeader>
          </div>
          <Formik
            validateOnChange={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formikProps) => {
              return (
                <>
                  {!isOrg && showSummarySection && (
                    <SummarySection
                      isSubmissionMode={submissionMode}
                      userInfo={userInfo}
                    />
                  )}
                  {isOrg && showSummarySection && (
                    <OrgSummarySection
                      isSubmissionMode={submissionMode}
                      oprCenterInfo={oprCenterInfo}
                      userInfo={userInfo}
                      isAdminView={isAdminView}
                    />
                  )}
                  {isOrg && (
                    <OrgEntitySection
                      isAdminView={isAdminView}
                      isW9={isW9}
                      setSSNOverrideFlag={setSSNOverrideFlag}
                      userInfo={userInfo}
                      setOtherOrgSelected={setOtherOrgSelected}
                      setllcSelected={setllcSelected}
                      {...formikProps}
                    />
                  )}
                  {showReasonCodeSection && (
                    <ReasonCodeSection
                      onReasonCodeSelection={(reasonCode) => {
                        setReasonCode(reasonCode);
                        formikProps.resetForm();
                      }}
                    />
                  )}
                  {!isOrg && showPersonalSection && (
                    <PersonalInformationSection {...formikProps} />
                  )}
                  {isOrg && !isAdminView && !isW9 && (
                    <BankAccountOwnerInformationSectionOrg
                      setHasBanking={setHasBanking}
                      setIsCorp={setIsCorp}
                      onBankAvailableChange={(value) => {
                        formikProps.resetForm();
                        // Keeping SSN Values after form is reset
                        formikProps.setFieldValue(
                          "SSN1",
                          formikProps.values.SSN1
                        );
                        formikProps.setFieldTouched("SSN1", true);
                        formikProps.setFieldValue(
                          "SSN2",
                          formikProps.values.SSN2
                        );
                        formikProps.setFieldTouched("SSN2", true);
                        formikProps.setFieldValue(
                          "SSN3",
                          formikProps.values.SSN3
                        );
                        formikProps.setFieldTouched("SSN3", true);
                        formikProps.setFieldValue(
                          "SSN1Confirm",
                          formikProps.values.SSN1Confirm
                        );
                        formikProps.setFieldTouched("SSN1Confirm", true);
                        formikProps.setFieldValue(
                          "SSN2Confirm",
                          formikProps.values.SSN2Confirm
                        );
                        formikProps.setFieldTouched("SSN2Confirm", true);
                        formikProps.setFieldValue(
                          "SSN3Confirm",
                          formikProps.values.SSN3Confirm
                        );
                        formikProps.setFieldTouched("SSN3Confirm", true);
                        // Keeping SSN Values after form is reset
                        setBankAvailable(value);
                        formikProps.setFieldValue("requestCheck", value);
                      }}
                      isDisabled={formikProps.values.requestCheck}
                      {...formikProps}
                    />
                  )}
                  {!isOrg && showBankAccountSection && (
                    <BankAccountOwnerInformationSection
                      onBankAvailableChange={(value) => {
                        formikProps.resetForm();
                        // Keeping SSN Values after form is reset
                        formikProps.setFieldValue(
                          "SSN1",
                          formikProps.values.SSN1
                        );
                        formikProps.setFieldTouched("SSN1", true);
                        formikProps.setFieldValue(
                          "SSN2",
                          formikProps.values.SSN2
                        );
                        formikProps.setFieldTouched("SSN2", true);
                        formikProps.setFieldValue(
                          "SSN3",
                          formikProps.values.SSN3
                        );
                        formikProps.setFieldTouched("SSN3", true);
                        formikProps.setFieldValue(
                          "SSN1Confirm",
                          formikProps.values.SSN1Confirm
                        );
                        formikProps.setFieldTouched("SSN1Confirm", true);
                        formikProps.setFieldValue(
                          "SSN2Confirm",
                          formikProps.values.SSN2Confirm
                        );
                        formikProps.setFieldTouched("SSN2Confirm", true);
                        formikProps.setFieldValue(
                          "SSN3Confirm",
                          formikProps.values.SSN3Confirm
                        );
                        formikProps.setFieldTouched("SSN3Confirm", true);
                        // Keeping SSN Values after form is reset
                        setBankAvailable(value);
                        formikProps.setFieldValue("requestCheck", value);
                      }}
                      isDisabled={formikProps.values.requestCheck}
                      {...formikProps}
                    />
                  )}
                  {showSignatureSection && (
                    <SignatureSection
                      isOrg={!!isOrg}
                      disableFields={
                        showSignatureSection &&
                        showSignatureSection.disableFields
                      }
                      hideAllButtons={
                        showSignatureSection &&
                        showSignatureSection.hideAllButtons
                      }
                      isDisabled={
                        edit &&
                        !reasonCode.personalInfo &&
                        !reasonCode.bankingInfo
                      }
                      userInfo={userInfo}
                      {...formikProps}
                    />
                  )}
                  {showAdminSection && (
                    <OCCRRAAdminSection
                      isOrg={!!isOrg}
                      userInfo={userInfo}
                      {...formikProps}
                    />
                  )}
                  {showGoBackButton && (
                    <ButtonContainer>
                      <FormButtonOutfilled
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Close Form
                      </FormButtonOutfilled>
                    </ButtonContainer>
                  )}
                </>
              );
            }}
          </Formik>
        </HeroSubmissionContainer>
      </Body>
    </>
  );
}
