import styled from "@emotion/styled";
import { FormikErrors, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  InputFull,
  Divider,
  FormInputLabel,
  ReviewHeader,
  InformationalHeader,
  FormButtonOutfilled,
  FormButtonfilled,
  DatePickerInput,
} from "src/components/forms";
import { InputRow } from "src/components/forms/InputRow";
import { UseUserInfoTypes } from "src/hooks/useUserInfo";
import Swal from "sweetalert2";
import { FormikInitValues } from "../HeroSubmissionForm";

const ButtonContainer = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
`;

const PerjuryText = styled.div`
  margin-left: 0.2em;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const UnorderedList = styled.ul`
  margin-top: 0;
`;

const OrderedList = styled.ol`
  margin-top: 0;
`;

const ListItem = styled.li`
  &::marker {
    font-size: 0.8em;
  }
  margin-top: 0.1em;
  margin-bottom: 0.3em;
`;

interface SignatureSectionTypes {
  isDisabled: boolean;
  hideAllButtons: boolean;
  disableFields: boolean;
  userInfo: UseUserInfoTypes;
  isOrg: boolean;
}

export function SignatureSection(
  props: FormikProps<FormikInitValues> & SignatureSectionTypes
) {
  const {
    submitForm,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    values,
    isDisabled,
    hideAllButtons,
    disableFields,
    submitCount,
    isValid,
    isSubmitting,
    isOrg,
  } = props;

  useEffect(() => {
    if (!isValid && !isSubmitting) {
      Swal.fire({
        title: "Validation Error",
        icon: "error",
        html: `Please make sure all fields have been filled in correctly`,
      });
    }
  }, [submitCount, isSubmitting]);
  return (
    <>
      <Divider />
      <ReviewHeader>
        By signing this form Electronically, I attest to the following:
      </ReviewHeader>
      {!isOrg && (
        <>
          <FormInputLabel>Hero Pay</FormInputLabel>
          <UnorderedList>
            <ListItem>
              <InformationalHeader noMargin>
                I understand that these payments are based upon continued
                employment of a child care program in an eligible role which
                will be confirmed for each payment.
              </InformationalHeader>
            </ListItem>
            <ListItem>
              <InformationalHeader noMargin>
                I understand that these are temporary payments and do not affect
                any other state of Ohio benefits I may receive.
              </InformationalHeader>
            </ListItem>
            <ListItem>
              <InformationalHeader noMargin>
                I understand that providing this information will result in the
                professional receiving an electronic IRS Form 1099. This will be
                available in the professional's Ohio Professional Registry
                profile the following calendar year and will be labeled with the
                tax year.
              </InformationalHeader>
            </ListItem>
            <ListItem>
              <InformationalHeader noMargin>
                The following Substitue W-9 is being completed by an individual.
              </InformationalHeader>
            </ListItem>
          </UnorderedList>
        </>
      )}
      {!isOrg && (
        <FormInputLabel>
          Internal Revenue Service (IRS){" "}
          <a
            href="https://www.irs.gov/instructions/iw9"
            target="_blank"
            rel="noreferrer noopener"
          >
            (https://www.irs.gov/instructions/iw9)
          </a>
        </FormInputLabel>
      )}
      <PerjuryText>
        <InformationalHeader noMargin>
          Under penalties of perjury, I certify that:
        </InformationalHeader>
      </PerjuryText>
      <OrderedList>
        <ListItem>
          <InformationalHeader noMargin>
            The number shown on this form is my correct taxpayer identification
            number (or I am waiting for a number to be issued to me); and
          </InformationalHeader>
        </ListItem>
        <ListItem>
          <InformationalHeader noMargin>
            I am not subject to backup withholding because: (a) I am exempt from
            backup withholding, or (b) I have not been notified by the Internal
            Revenue Service (IRS) that I'm subject to backup withholding as a
            result of a failure to report all interest or dividends , or (c) the
            IRS has notified me that I am no longer subject to backup
            withholding; and
          </InformationalHeader>
        </ListItem>
        <ListItem>
          <InformationalHeader noMargin>
            I am a U.S citizen or other U.S. person; and
          </InformationalHeader>
        </ListItem>
        <ListItem>
          <InformationalHeader noMargin>
            The FATCA code(s) entered on the form (if any) indicating that I am
            exempt from FATCA reporting is correct.
          </InformationalHeader>
        </ListItem>
      </OrderedList>

      <InputRow
        errorLabelCypressLabel="SignatureSectionError"
        rowText={
          isOrg
            ? " "
            : "Please enter your name as it is shown in your bank statements."
        }
        isError={
          !!(
            (errors.signatureDate && touched.signatureDate) ||
            (errors.signature && touched.signature)
          )
        }
        errorText={
          (errors.signature && touched.signature ? errors.signature : "") ||
          (errors.signatureDate && touched.signatureDate
            ? errors.signatureDate
            : "")
        }
        rows={[
          <InputFull
            disabled={isDisabled || disableFields}
            value={values.signature}
            data-cy="signatureSectionSignature"
            error={!!(errors.signature && touched.signature)}
            onChange={(e) => {
              setFieldValue("signature", e.target.value, false);
            }}
            onBlur={(e) => {
              setFieldTouched("signature", true, false);
              setFieldValue("signature", e.target.value, true);
            }}
          >
            {isOrg ? "Authorized Signer" : "Electronic Signature"}
          </InputFull>,
          <DatePickerInput
            defaultDate={
              values.signatureDate ? new Date(values.signatureDate) : null
            }
            disabled={true}
            name="signatureSectionSignatureDate"
            onCalendarClose={() => {
              setFieldTouched("signatureDate", true, false);
              setFieldValue("signatureDate", values.signatureDate, true);
            }}
            isError={!!(errors.signatureDate && touched.signatureDate)}
            onChange={(date) => {
              setFieldValue("signatureDate", date, true);
            }}
          >
            Date
          </DatePickerInput>,
        ]}
      />
      {!hideAllButtons && (
        <ButtonContainer>
          <FormButtonfilled
            disabled={isDisabled || isSubmitting}
            data-cy="submitForm"
            onClick={() => {
              submitForm();
            }}
          >
            Submit Form{" "}
          </FormButtonfilled>
        </ButtonContainer>
      )}
    </>
  );
}
