import React, { useState } from "react";
import DatePicker from "react-date-picker";
import { Global, css } from "@emotion/react";
import COLORS from "../colors";
import { FormInputContainer, FormInputLabel } from ".";

interface DatePickerInputType {
  onChange?(date: Date | Date[]): void;
  children?: React.ReactNode;
  isError?: boolean;
  onCalendarClose?(): void;
  name?: string;
  disabled?: boolean;
  defaultDate?: Date | Date[] | null;
}

export const DatePickerInput = (props: DatePickerInputType) => {
  const {
    onChange,
    children,
    isError,
    onCalendarClose,
    name,
    disabled,
    defaultDate,
  } = props;
  const [dateValue, setDateValue] = useState<Date | Date[] | null>(
    defaultDate || null
  );
  return (
    <>
      <Global
        styles={css`
          .react-date-picker {
            height: 2em;
            .react-date-picker__wrapper {
              border: 1px solid ${COLORS.LIGHT_GRAY};
            }
            &.pickerError .react-date-picker__wrapper {
              border: 1px solid ${COLORS.RED};
              color: ${COLORS.RED};
              .react-date-picker__inputGroup__input {
                color: ${COLORS.RED};
              }
            }
            .react-date-picker__inputGroup__input:focus {
              outline: none;
            }
          }
        `}
      />
      <FormInputContainer>
        {children && (
          <FormInputLabel data-cy={`${name}-label`} error={isError}>
            {children}
          </FormInputLabel>
        )}
        <DatePicker
          disabled={disabled}
          name={name}
          onCalendarClose={onCalendarClose}
          className={isError ? "pickerError" : ""}
          onChange={(date) => {
            setDateValue(date);
            onChange && onChange(date);
          }}
          value={defaultDate}
        />
      </FormInputContainer>
    </>
  );
};
