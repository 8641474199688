import { useState } from "react";

import styled from "@emotion/styled";
import COLORS from "../colors";
import { DatePickerInput, FormButtonfilled, FormInputLabel } from "..";

interface NavTabTypes {
  selected?: boolean;
}

const SmallDivider = styled.span`
  align-self: center;
  justify-self: center;
  flex: 0.3;
  text-align: center;
  padding-right: 0.6em;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  margin: 0.7em 0;
  align-items: center;
`;

const RadioButton = styled.input`
  margin-bottom: 0.1em;
  margin-right: 0.7em;
  height: 1.5em;
  width: 1.5em;
  margin-left: 0;
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface RequestListTypes {
  onGenerate(
    reportType: string,
    dateFrom: string,
    dateTo: string,
    reportTarget: string
  ): void;
}

export function Export(props: RequestListTypes) {
  const { onGenerate } = props;
  const [reportType, setReportType] = useState("vendorExport");
  const [reportTarget, setReportTarget] = useState("Individuals");
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ marginRight: "4em" }}>
          <FormInputLabel>
            Please specify if the export is for individuals or organizations:
          </FormInputLabel>
          <RadioButtonContainer>
            <RadioButton
              defaultChecked
              type="radio"
              onChange={() => {
                setReportTarget("Individuals");
              }}
              name="reportTarget"
            />{" "}
            Individuals
          </RadioButtonContainer>
          <RadioButtonContainer>
            <RadioButton
              type="radio"
              name="reportTarget"
              onChange={() => {
                setReportTarget("Organizations");
              }}
            />{" "}
            Organizations
          </RadioButtonContainer>
        </div>
        <div style={{ marginRight: "4em" }}>
          <FormInputLabel>Please select the report to export:</FormInputLabel>
          <RadioButtonContainer>
            <RadioButton
              defaultChecked
              type="radio"
              onChange={() => {
                setReportType("vendorExport");
              }}
              name="reportName"
            />{" "}
            Vendor Export Report
          </RadioButtonContainer>
          <RadioButtonContainer>
            <RadioButton
              type="radio"
              name="reportName"
              onChange={() => {
                setReportType("invoiceExport");
              }}
            />
            Invoice Export Report
          </RadioButtonContainer>
          <RadioButtonContainer>
            <RadioButton
              type="radio"
              name="reportName"
              onChange={() => {
                setReportType("vendorChanges");
              }}
            />
            Change Export Report
          </RadioButtonContainer>
          <RadioButtonContainer>
            <RadioButton
              type="radio"
              name="reportName"
              onChange={() => {
                setReportType("odjfsInvoiceExport");
              }}
            />
            ODJFS Invoice Export Report
          </RadioButtonContainer>
        </div>

        <div>
          <FormInputLabel>Please select the date range:</FormInputLabel>
          <DatePickerContainer>
            <div style={{ flex: 1 }}>
              <DatePickerInput
                defaultDate={dateFrom ? new Date(dateFrom) : null}
                name="fromDate"
                onCalendarClose={() => {}}
                isError={false}
                onChange={(date) => {
                  const parsedDate = date
                    ? new Date(date.toString()).toLocaleDateString("en-US")
                    : "";
                  setDateFrom(parsedDate);
                }}
              />
            </div>
            <SmallDivider>-</SmallDivider>
            <div style={{ flex: 1 }}>
              <DatePickerInput
                defaultDate={dateTo ? new Date(dateTo) : null}
                name="toDate"
                onCalendarClose={() => {}}
                isError={false}
                onChange={(date) => {
                  const parsedDate = date
                    ? new Date(date.toString()).toLocaleDateString("en-US")
                    : "";
                  setDateTo(parsedDate);
                }}
              />
            </div>
          </DatePickerContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 1,
              marginTop: "1em",
            }}
          >
            <FormButtonfilled
              onClick={() => {
                console.log(dateFrom);
                onGenerate(reportType, dateFrom!, dateTo!, reportTarget);
              }}
              disabled={dateFrom === "" || dateTo === ""}
            >
              Generate Report
            </FormButtonfilled>
          </div>
        </div>
      </div>
    </>
  );
}
