import React from "react";
import { FormInputContainer, FormInputField, FormInputLabel } from ".";

interface InputFullType {
  children?: React.ReactNode;
  small?: boolean;
  maxLength?: number;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(e: React.ChangeEvent<HTMLInputElement>): void;
  error?: boolean;
  id?: string;
  type?: string;
  disabled?: boolean;
  value?: string;
  "data-cy"?: string;
}

export const InputFull = (props: InputFullType) => {
  const {
    children,
    small,
    maxLength,
    onChange,
    error,
    id,
    type,
    disabled,
    onBlur,
    value,
  } = props;
  return (
    <FormInputContainer small={!!small}>
      {children && (
        <FormInputLabel data-cy={`${props["data-cy"]}-label`} error={error}>
          {children}
        </FormInputLabel>
      )}
      <FormInputField
        data-cy={props["data-cy"]}
        value={value}
        id={id}
        type={type}
        error={error}
        onChange={onChange}
        maxLength={maxLength}
        small={!!small}
        disabled={disabled}
        onBlur={onBlur}
      />
    </FormInputContainer>
  );
};
