import styled from "@emotion/styled";
import React, { useState } from "react";
import {
  FormHeader,
  InputFull,
  FormContainer,
  CheckBox,
  FormButtonOutfilled,
  FormButtonfilled,
} from "src/components/forms";
import { UseUserInfoTypes } from "src/hooks/useUserInfo";
import { Link, useHistory } from "react-router-dom";

const ConfirmationContainer = styled.div`
  align-items: flex-end;
  display: inline-flex;
  padding: 2em 1em 0em;
  flex-direction: column;
  border-radius: 0.3em;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  margin-top: 1em;
  display: flex;
`;

interface SummaryFormTypes {
  isSubmissionMode?: boolean;
  userInfo: UseUserInfoTypes;
}

export function SummarySection(props: SummaryFormTypes) {
  const { isSubmissionMode, userInfo } = props;
  const history = useHistory();
  const [accuracyCheck, setAccuracyCheck] = useState(false);

  return (
    <>
      <FormHeader>OPR Profile Information</FormHeader>
      <FormContainer>
        <InputFull
          data-cy="SummarySection-First"
          value={userInfo.first_name}
          disabled
        >
          First Name{" "}
        </InputFull>
        <InputFull
          data-cy="SummarySection-Last"
          value={userInfo.last_name}
          disabled
        >
          Last Name{" "}
        </InputFull>
        <InputFull data-cy="SummarySection-OPIN" value={userInfo.opin} disabled>
          OPIN Number{" "}
        </InputFull>
        <InputFull
          data-cy="SummarySection-Email"
          value={userInfo.email}
          disabled
        >
          Email Address
        </InputFull>
        <InputFull
          data-cy="SummarySection-Phone"
          value={userInfo.phone}
          disabled
        >
          Phone Number{" "}
        </InputFull>
      </FormContainer>
      <FormContainer>
        <InputFull
          data-cy="SummarySection-Address"
          value={userInfo.address}
          disabled
        >
          Address{" "}
        </InputFull>
        <InputFull data-cy="SummarySection-City" value={userInfo.city} disabled>
          City{" "}
        </InputFull>
        <InputFull
          data-cy="SummarySection-State"
          value={userInfo.state}
          disabled
        >
          State{" "}
        </InputFull>
        <InputFull
          data-cy="SummarySection-County"
          value={userInfo.county}
          disabled
        >
          County
        </InputFull>
        <InputFull
          data-cy="SummarySection-Zip"
          value={userInfo.zipCode}
          disabled
        >
          Zip Code{" "}
        </InputFull>
      </FormContainer>
      {isSubmissionMode && (
        <ConfirmationContainer>
          <CheckBox
            data-cy="acknowledgementCheckBox"
            checked={accuracyCheck}
            onCheck={(checked) => {
              setAccuracyCheck(checked);
            }}
            subLabel="(Please note your name must match your bank account information, if not please edit your OPR Profile)"
          >
            By selecting this checkbox, I acknowledge that all information on
            this screen is accurate.
          </CheckBox>
          <ButtonContainer>
            <FormButtonOutfilled
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel{" "}
            </FormButtonOutfilled>
            <FormButtonOutfilled
              onClick={() => {
                window.location.href = userInfo.returnUrl;
              }}
            >
              Edit Profile{" "}
            </FormButtonOutfilled>
            <Link to={`/create_request/submission`}>
              <FormButtonfilled disabled={!accuracyCheck}>
                Start Request{" "}
              </FormButtonfilled>
            </Link>
          </ButtonContainer>
        </ConfirmationContainer>
      )}
    </>
  );
}
