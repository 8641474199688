import styled from "@emotion/styled";
import COLORS from "../colors";

export const Input = styled.input`
  padding: 0.75em;
  border-radius: 0.5em;
  border: thin solid gray;
`;

export const Label = styled.label`
  font-weight: 700;
  margin-bottom: 0.5em;
`;

export const ErrorLabel = styled.label`
  color: red;
  font-size: 0.8em;
  min-height: 1.4em;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

export const FormTitle = styled.label`
  display: flex;
  font-size: 1.5em;
  font-weight: bold;
  color: ${COLORS.BLUE};
`;

export const FormHeader = styled.label`
  display: inline-flex;
  font-size: 1.2em;
  font-weight: bold;
  color: ${COLORS.BLACK};
  margin-bottom: 0.5em;
  flex: 1;
`;

export const FormSubHeader = styled.label`
  display: inline-flex;
  font-size: 0.7em;
  font-weight: bold;
  color: ${COLORS.BLACK};
  margin-bottom: 0.5em;
  flex: 2;
`;

interface InformationalHeaderTypes {
  noMargin?: boolean;
  error?: boolean;
  boldText?: boolean;
}

export const InformationalHeader = styled.label`
  align-items: center;
  display: flex;
  font-size: 0.8em;
  margin: ${(props: InformationalHeaderTypes) =>
    props.noMargin ? "0em" : "2em 0em 0.5em 0em"};
  color: ${(props: FormInputLabelTypes) =>
    props.error ? COLORS.RED : "unset"};
`;

export const ReviewHeaderLarge = styled.label`
  display: flex;
  font-size: 1em;
  font-weight: bold;
  margin: 1.3em 0.5em 2.5em 0em;
`;

export const ReviewHeader = styled.label`
  display: flex;
  font-size: 0.7em;
  font-weight: bold;
  font-style: italic;
  margin: 1.3em 0.5em 2.5em 0em;
`;

interface FormInputFieldTypes {
  small?: boolean;
  maxLength?: number;
  onChange?(e: React.FormEvent<HTMLInputElement>): void;
  error?: boolean;
  disabled?: boolean;
}

export const FormInputField = styled.input`
  padding: 0.5em;
  border-radius: 2px;
  border: 1px solid
    ${(props: FormInputFieldTypes) =>
      props.error ? COLORS.RED : COLORS.LIGHT_GRAY};
  color: ${COLORS.GRAY};
  width: ${(props: FormInputFieldTypes) => (props.small ? "2.5em" : "unset")};
  background-color: ${(props: FormInputFieldTypes) =>
    props.disabled ? "#d2d2d2" : "white"};
`;

interface FormInputLabelTypes {
  error?: boolean;
  weight?: string;
}

export const FormInputLabel = styled.label`
  display: flex;
  font-size: 0.7em;
  margin: 0em 0em 0.5em;
  align-items: center;
  color: ${(props: FormInputLabelTypes) =>
    props.error ? COLORS.RED : COLORS.BLACK};
  font-weight: ${(props: FormInputLabelTypes) =>
    props.weight ? props.weight : "bold"};
`;

interface FormInputContainerTypes {
  small?: boolean;
}

export const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0em 0.5em 0.5em 0em;
  flex: ${(props: FormInputContainerTypes) => (props.small ? "0" : "1")};
`;

interface FormCheckboxContainerTypes {
  reverse?: boolean;
}

export const FormCheckboxContainer = styled.div`
  flex-direction: ${(props: FormCheckboxContainerTypes) =>
    props.reverse ? "row-reverse" : "row"};
  justify-content: flex-end;
  align-items: center;
  display: inline-flex;
  margin: ${(props: FormCheckboxContainerTypes) =>
    props.reverse ? "0.5em 0em" : "0.5em 0.5em"};
`;

interface FormContainerTypes {
  justifyContent?: string;
}

export const FormContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: ${(props: FormContainerTypes) =>
    props.justifyContent ? props.justifyContent : "unset"};
`;

interface FormCheckBoxTypes {
  checkBoxSize?: string;
}

export const FormCheckBox = styled.input`
  display: flex;
  height: ${(props: FormCheckBoxTypes) =>
    props.checkBoxSize ? props.checkBoxSize : "1em"};
  width: ${(props: FormCheckBoxTypes) =>
    props.checkBoxSize ? props.checkBoxSize : "1em"};
`;

interface FormCheckBoxLabelTypes {
  reverse?: boolean;
  weight?: string;
}

export const FormCheckBoxLabel = styled.label`
  display: inline-flex;
  align-items: center;
  font-size: 0.8em;
  font-weight: ${(props: FormCheckBoxLabelTypes) =>
    props.weight ? props.weight : "bold"};
  margin-left: ${(props: FormCheckBoxLabelTypes) =>
    props.reverse ? "0em" : "0.4em"};
  margin-right: ${(props: FormCheckBoxLabelTypes) =>
    props.reverse ? "0.4em" : "0em"};
`;

export const FormSubCheckBoxLabel = styled.label`
  display: inline-flex;
  font-size: 0.7em;
  font-weight: bold;
  margin-left: 3.5em;
`;

export const FormButtonOutfilled = styled.button`
  &:active {
    opacity: 0.6;
  }
  cursor: pointer;
  padding: 1em;
  background-color: unset;
  color: ${COLORS.BLUE};
  border-radius: 0.3em;
  border: 2px solid ${COLORS.LIGHT_BLUE};
  margin: 0 0.6em;
`;

interface FormButtonfilledTypes {
  disabled?: boolean;
}

export const FormButtonfilled = styled.button`
  &:active {
    opacity: 0.6;
  }
  cursor: pointer;
  padding: 1em 2em;
  background-color: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  border-radius: 0.3em;
  border: 2px solid ${COLORS.BLUE};
  margin: 0 0.6em;
  opacity: ${(props: FormButtonfilledTypes) => (props.disabled ? "0.4" : "1")};
`;

export const Divider = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid black;
  margin: 1em 0em;
`;

export * from "./InputFull";

export * from "./CheckBox";

export * from "./DatePickerInput";
