import React, { useEffect, useMemo, useState } from "react";

import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useFilters,
} from "react-table";
import { PaginationControls, Styles } from "./";
import { LoaderContainer } from "src/components/Loader";
import SortArrow from "./SortArrow";
import { matchSorter } from "match-sorter";
import Select from "react-select";
import { useHistory, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import styled from "@emotion/styled";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";
const DULL_GRAY = "rgb(204, 204, 204)";

const SearchInput = styled.input`
  padding: 0em 0.7em;
  padding-bottom: 0em;
  border-radius: 0.25em;
  max-height: 38px;
  font-size: 12px;
  border: thin solid ${DULL_GRAY};
  width: 100%;
`;

const StyledSelect = styled(Select)`
  // padding: 0.25em;
  padding: 0em;
  padding-bottom: 0em;
  font-size: 12px;
  font-family: Lato, sans-serif;
  // max-height: 36px;
  width: 100%;
  font-weight: normal;
`;

const TableLink = styled(Link)`
  width: 100%;
  display: flex;
  color: black;
  text-decoration: none;
`;
const DEBUG = false;

export function DatePickerFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setFilter([start, end]);
    setEndDate(end);
  };
  return (
    <div style={{ display: "flex" }}>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        showMonthDropdown
        showYearDropdown
        // inline
      />
      <button
        onClick={() => {
          setFilter(undefined);
          setStartDate(null);
          setEndDate(null);
        }}
      >
        x
      </button>
    </div>
  );
}

const fDate = (date) => DateTime.fromJSDate(date).toISODate();

export function DatePickerFilter2({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setFilter([fDate(startDate), fDate(endDate)]);
  }, [startDate, endDate]);

  return (
    <div style={{ display: "flex", marginTop: "0.5em", marginBottom: "0.5em" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          showMonthDropdown
          showYearDropdown
          startDate={startDate}
          endDate={endDate}
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          showMonthDropdown
          showYearDropdown
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </div>
      <button
        onClick={() => {
          setFilter(undefined);
          setStartDate(null);
          setEndDate(null);
        }}
      >
        x
      </button>
    </div>
  );
}
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const statusOptions = [
  { value: "approved", label: "Approved" },
  { value: "unable_to_verify", label: "Unable To Verify" },
];

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  // const count = (preFilteredRows).length;

  return (
    <div
      style={{
        display: "flex",
        padding: "0.5em 0.125em ",
        paddingBottom: "0em",
        height: "48px",
      }}
    >
      <SearchInput
        value={filterValue || ""}
        onClick={(e) =>
          e.stopPropagation() &&
          e.nativeEvent &&
          e.nativeEvent.stopImmediatePropagation()
        }
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search records...`}
      />
    </div>
  );
}

export const selectColumnFilter = (options) => ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  // const count = preFilteredRows.length;

  return (
    <div
      style={{
        display: "flex",
        padding: "0.5em 0.125em ",
        paddingBottom: "0em",
        height: "48px",
      }}
    >
      <StyledSelect
        value={options.find((x) => x.value === filterValue)}
        options={options}
        onClick={(e) =>
          e.stopPropagation() &&
          e.nativeEvent &&
          e.nativeEvent.stopImmediatePropagation()
        }
        onChange={(e) => {
          // console.log(e);
          setFilter(e.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search records...`}
      />
    </div>
  );
};

function PaginatedTable({
  columns = [],
  data = [],
  fetchData = undefined,
  isLoading,
  pageCount: controlledPageCount,
  initialState = {},
}) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const history = useHistory();
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  // const selectColumn = useMemo(() => ({ Filter: SelectColumnFilter }), []);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, groupBy, expanded, filters },
    // filterTypes
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      defaultColumn,
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      pageCount: controlledPageCount,
      initialState: {
        ...{
          pageIndex: 0,
          pageSize: 10,
          sortBy: [
            {
              id: columns[1].accessor,
              desc: false,
            },
          ],
        },
        ...initialState,
      },
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
    usePagination
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: "selection",
    //       // Header: ({getToggleAllRowsSelectedProps}) => <div></div>,
    //       width: 16,
    //       noSort: true,
    //       Cell: ({ row }) => <input type="radio" value={row.original.id} />,
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  useEffect(() => {
    fetchData({ pageSize, pageIndex, sortBy, filters });
  }, [fetchData, pageSize, pageIndex, sortBy, filters]);

  // Render the UI for your table
  return (
    <div className="standard-table">
      {DEBUG && (
        <pre>
          <code>
            {JSON.stringify(
              {
                pageIndex,
                pageSize,
                pageCount,
                canNextPage,
                canPreviousPage,
              },
              null,
              2
            )}
          </code>
        </pre>
      )}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  style={{
                    paddingBottom: "0em",
                    // style={{
                    //   fontWeight: "bolder",
                    //   padding: "0em 1em",
                    //   fontSize: "0.8rem",
                    //   textAlign: "start",
                    minWidth: column.minWidth || "unset",
                    width: column.width || "unset",
                    maxWidth: column.maxWidth || "unset",
                  }}
                >
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      fontWeight: "bolder",
                      display: "flex",
                      padding: "0em",
                      cursor: "pointer",
                      paddingBottom: "0em",
                      fontSize: "0.8rem",
                      textAlign: "start",
                    }}
                  >
                    <span style={{ flex: "auto" }}>
                      {column.render("Header")}
                    </span>
                    {!column.noSort && (
                      <SortArrow
                        isSortedDesc={column.isSortedDesc}
                        isSorted={column.isSorted}
                      />
                    )}
                  </div>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading ? (
            <tr rowSpan={10}>
              <td colSpan={columns.length}>
                <LoaderContainer height="300px" />
              </td>
            </tr>
          ) : page.length === 0 ? (
            <tr rowSpan={5}>
              <td colSpan={columns.length}>
                <div className="no-records">No Records To Show</div>
              </td>
            </tr>
          ) : (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let params = {};
                    console.log(row.original);
                    if (
                      !!(row.original.licenseNumber && row.original.programName)
                    ) {
                      params = new URLSearchParams({
                        isOrg: true,
                        skipUserInfo: true,
                        first_name: row.original.firstName,
                        last_name: row.original.lastName,
                        opin: row.original.opin,
                        paymentMethod: row.original.paymentMethod,
                        vendorStatus: row.original.vendorStatus,
                        requestId: row.original.requestId,
                        email: row.original.email,
                        address: row.original.address,
                        city: row.original.city,
                        program_cnty: row.original.county,
                        signature: row.original.signature,
                        signatureDate: row.original.signatureDate,
                        state: row.original.state,
                        zipCode: row.original.zip,
                        phone: row.original.phone,
                        w9EntityType: row.original.w9EntityType,
                        w9ExemptPayeeCode: row.original.w9ExemptPayeeCode,
                        w9EntityOtherInfo: row.original.w9EntityOtherInfo,
                        w9EntityClassification:
                          row.original.w9EntityClassification,
                        W9ExemptFATCACode: row.original.W9ExemptFATCACode,
                        fiscalRepresentativeEmail:
                          row.original.fiscalRepresentativeEmail,
                        fiscalRepresentativeName:
                          row.original.fiscalRepresentativeName,
                        nameAsOnTaxReturn: row.original.nameAsOnTaxReturn,
                        tin: row.original.tin,
                        program_name: row.original.programName,
                        license_number: row.original.licenseNumber,
                        program_addr_1: row.original.mailingAddress,
                        program_addr_2: "",
                        program_city: row.original.mailingCity,
                        program_state: row.original.mailingState,
                        program_zip: row.original.mailingZip,
                      });
                    } else {
                      params = new URLSearchParams({
                        skipUserInfo: true,
                        first_name: row.original.firstName,
                        last_name: row.original.lastName,
                        opin: row.original.opin,
                        paymentMethod: row.original.paymentMethod,
                        vendorStatus: row.original.vendorStatus,
                        requestId: row.original.requestId,
                        email: row.original.email,
                        address: row.original.address,
                        city: row.original.city,
                        county: row.original.county,
                        signature: row.original.signature,
                        signatureDate: row.original.signatureDate,
                        state: row.original.state,
                        zipCode: row.original.zip,
                        phone: row.original.phone,
                        w9EntityType: row.original.w9EntityType,
                        w9ExemptPayeeCode: row.original.w9ExemptPayeeCode,
                        w9EntityOtherInfo: row.original.w9EntityOtherInfo,
                        w9EntityClassification:
                          row.original.w9EntityClassification,
                        W9ExemptFATCACode: row.original.W9ExemptFATCACode,
                        fiscalRepresentativeEmail:
                          row.original.fiscalRepresentativeEmail,
                        fiscalRepresentativeName:
                          row.original.fiscalRepresentativeName,
                        nameAsOnTaxReturn: row.original.nameAsOnTaxReturn,
                        tin: row.original.tin,
                      });
                    }

                    if (window.location.search) {
                      history.push(
                        `/request/${row.original.requestId}${window.location.search}&${params}`
                      );
                    } else {
                      history.push(
                        `/request/${row.original.requestId}?${params}`
                      );
                    }
                  }}
                >
                  {row.cells.map((cell) => {
                    return row.original.link ? (
                      <td {...cell.getCellProps()}>
                        <TableLink
                          to={row.original.link + window.location.search}
                        >
                          <div style={{ padding: "0.5em" }}>
                            {cell.render("Cell")}
                          </div>
                        </TableLink>
                      </td>
                    ) : (
                      <td {...cell.getCellProps()}>
                        <div style={{ padding: "0.5em" }}>
                          {cell.render("Cell")}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
          {!isLoading &&
            Array(pageSize - page.length)
              .fill(0)
              .map((x) => (
                <tr style={{ height: "36px" }}>
                  {Array(columns.length)
                    .fill(0)
                    .map((y) => (
                      <td style={{ backgroundColor: "#f8f8f8" }}></td>
                    ))}
                </tr>
              ))}
        </tbody>
      </table>
      <PaginationControls
        {...{
          canPreviousPage,
          canNextPage,
          gotoPage,
          previousPage,
          nextPage,
          pageCount,
          pageSize,
          pageOptions,
          pageIndex,
          setPageSize,
        }}
      />
    </div>
  );
}

function Table({ columns = [], data = [], isLoading }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    // The rest of these things are super handy, too ;)
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    useSortBy
  );

  // Render the UI for your table
  return (
    <div className="standard-table">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    fontWeight: "bolder",
                    fontSize: "0.8rem",
                    padding: "1em",
                    minWidth: column.minWidth || "unset",
                    width: column.width || "unset",
                    maxWidth: column.maxWidth || "unset",
                  }}
                >
                  {!columns.noSort && (
                    <SortArrow
                      isSortedDesc={column.isSortedDesc}
                      isSorted={column.isSorted}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading ? (
            <tr rowSpan={5}>
              <td colSpan={columns.length}>
                <LoaderContainer height="auto" />
              </td>
            </tr>
          ) : rows.length === 0 ? (
            <tr rowSpan={5}>
              <td colSpan={columns.length}>
                <div className="no-records">No Records To Show</div>
              </td>
            </tr>
          ) : (
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return row.original.link ? (
                      <Link to={row.original.link + window.location.search}>
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      </Link>
                    ) : (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}

const StandardTable = ({
  isOrg,
  columns,
  data,
  fetchData,
  pageCount,
  isLoading,
  usePagination = true,
}) => {
  const DynamicTable = usePagination ? PaginatedTable : Table;

  return (
    <Styles style={isOrg ? { width: "125%" } : {}}>
      <DynamicTable {...{ columns, data, fetchData, pageCount, isLoading }} />
    </Styles>
  );
};

export { StandardTable };
