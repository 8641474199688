// import Yubikey from "./Yubikey.gif";
import { useContext, useEffect } from "react";

import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { Input, Label, InputContainer } from "src/components/forms";
import { AuthContext } from "src/providers/AuthProvider";
import { CognitoUser } from "amazon-cognito-identity-js";
import { RippleButton } from "src/components/buttons";
import { useFormik } from "formik";
import routes from "src/routes";

const LoginCard = styled.div`
  background: #f0f0f0;
  border-radius: 0.5em;
  padding: 1.5em;
  display: flex;
  max-width: 500px;
  margin: auto;
  margin-top: 2em;
`;

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export function CreateNewPassword(props: any) {
  const auth: any = useContext(AuthContext);
  const history = useHistory();
  const {} = props;
  const { resetPassword, userAttributes, isLoggedIn } = auth;

  useEffect(() => {
    if (!userAttributes) history.push("/login" + window.location.search);
    if (isLoggedIn) history.push(routes.ADMIN + window.location.search);
  }, [userAttributes, isLoggedIn]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      code: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      const { email, password, confirmPassword } = values;
      const newPassword = password && password === confirmPassword && password;
      resetPassword(email, newPassword);
    },
  });

  const { handleChange, handleSubmit, values } = formik;

  return (
    <LoginContainer>
      <LoginCard>
        <form onSubmit={handleSubmit}>
          <h2>Create New Password</h2>
          <div>
            <ul>
              <li>The user is accessing a restricted information system.</li>
              <li>
                System usage may be monitored, recorded, and subject to audit.
              </li>
              <li>
                Unauthorized use of the system is prohibited and may be subject
                to criminal and/or civil penalties.
              </li>
              <li>
                Use of the system indicates consent to monitoring and recording.
              </li>
            </ul>

            <InputContainer>
              <Label>Password</Label>
              <Input
                id="password"
                name="password"
                type="password"
                onChange={handleChange}
                value={values.password}
              />
            </InputContainer>
            <InputContainer>
              <Label>Confirm Password</Label>
              <Input
                id="confirm_password"
                name="confirmPassword"
                type="password"
                onChange={handleChange}
                value={values.confirmPassword}
              />
            </InputContainer>

            <RippleButton type="submit">Submit</RippleButton>
          </div>
        </form>
      </LoginCard>
    </LoginContainer>
  );
}
