import {
  selectColumnFilter,
  StandardTable,
  DatePickerFilter2,
  SelectOption,
  displaySelectCell,
  displayDateCell,
} from "src/components/StandardTable";
import { useState, useCallback } from "react";

import Axios from "axios";

export const statusOptions: SelectOption[] = [
  { value: "", label: "" },
  { value: "submitted", label: "Submitted" },
  { value: "processing", label: "Processing" },
  { value: "processed", label: "Processed" },
  { value: "unabletoverify", label: "Unable To Verify" },
];

const paymentOptions: SelectOption[] = [
  { value: "", label: "" },
  { value: "Electronic Funds Transfer", label: "Electronic Funds Transfer" },
  { value: "Check", label: "Check" },
];

const activeOptions: SelectOption[] = [
  { value: "", label: "" },
  { value: "0", label: "No" },
  { value: "1", label: "Yes" },
];

export type RequestRecord = {
  full_name: string;
  status: string;
  opin: string | number;
  submitted_date: string;
  modified_date: string;
  vendor_id: string | number;
  link: string;
  payment_method: string;
};

export default function RequestList(props: any) {
  const [adminQueue, setAdminQueue] = useState<RequestRecord[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const { isOrg } = props;

  const get = useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
    setIsLoading(true);

    Axios.get("/api/vendors", {
      params: {
        offset: pageIndex,
        limit: pageSize,
        ...filters.reduce((acc: any, x: any) => {
          if (x.id) {
            acc[x.id] = x.value;
          }
          return acc;
        }, {}),
        sortBy: sortBy.map(({ id, desc }: { id: string; desc: Boolean }) => ({
          fieldName: id,
          direction: desc ? "DESC" : "ASC",
        })),
      },
    })
      .then(({ data: { data, total } }) => {
        setAdminQueue(data);
        setPageCount(Math.ceil(total / pageSize));
        // setPageCount(Math.ceil(data.length / pageSize));
      })
      .finally(() => setIsLoading(false));
  }, []);

  const orgColumn = [
    {
      accessor: "requestId",
      Header: "Request Id",
      sortable: false,
    },

    {
      accessor: "firstName",
      Header: "First Name",
    },
    {
      accessor: "lastName",
      Header: "Last Name",
    },
    {
      accessor: "programName",
      Header: "Program Name",
    },
    {
      accessor: "licenseNumber",
      Header: "License Number",
      width: 160,
    },
    {
      accessor: "payee",
      Header: "Payee Name",
    },
    {
      accessor: "vendorStatus",
      Header: "Vendor Status",
      Filter: selectColumnFilter(statusOptions),
      Cell: displaySelectCell(statusOptions),
    },
    {
      accessor: "opin",
      Header: "OPIN",
      Cell: ({ cell }: any) =>
        cell.value.length === 8
          ? cell.value.slice(0, 4) + "-" + cell.value.slice(4, 8)
          : cell.value,
    },
    {
      accessor: "submittedDate",
      Header: "Submitted Date",
      Filter: DatePickerFilter2,
      Cell: displayDateCell,
    },
    {
      accessor: "paymentMethod",
      Header: "Payment Method",
      Filter: selectColumnFilter(paymentOptions),
      Cell: displaySelectCell(paymentOptions),
    },
    {
      accessor: "vendorId",
      Header: "Vendor ID",
    },
    {
      accessor: "active",
      Header: "Active",
      Filter: selectColumnFilter(activeOptions),
      Cell: ({ cell }: any) => (Number(cell.value) ? "Yes" : "No"),
    },
  ];

  return (
    <StandardTable
      isOrg={!!isOrg}
      data={adminQueue}
      pageCount={pageCount}
      fetchData={get}
      columns={orgColumn}
      isLoading={isLoading}
      usePagination={true}
    />
  );
}
