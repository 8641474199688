// import Yubikey from "./Yubikey.gif";
import styled from "@emotion/styled";
import COLORS from "./colors";
import { FormTitle, InformationalHeader } from "src/components/forms";

const Body = styled.div`
  display: flex;
  flex: 1;
  padding: 2em 1em;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 0.7;
  padding: 2em 1em;
  flex-direction: column;
  border: 1px solid ${COLORS.LIGHT_GRAY};
  border-radius: 0.3em;
`;

const Logo = styled.img`
  position: absolute;
  right: 0;
  top: 5px;
`;

export default function ErrorMessage() {
  return (
    <Body>
      <Container>
        <Logo
          height={100}
          src={require("../assets/icons/security-ssl.svg").default}
        />
        <div>
          <FormTitle>An Error has occurred</FormTitle>
        </div>
        <div>
          <InformationalHeader>
            An Error has occurred while loading this page.&nbsp;
            <a href="/hero-summary">Please click here</a>&nbsp;to try again{" "}
          </InformationalHeader>
        </div>
      </Container>
    </Body>
  );
}
